import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { AccountInfo, AccountInfoFormData } from "./accountInfo.type";

export const AccountInfoApi = createApi({
  reducerPath: "accountInfoApi",
  tagTypes: ["accountInfo", "accountInfoByUser"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    accountInfoList: builder.query<PaginationResults<AccountInfo>, TypeQuery>({
      query: (query) => QueryUrl("mode_receptions/", query),
      providesTags: ["accountInfo"],
    }),
    addOrUpdateAccountInfo: builder.mutation<
      AccountInfo,
      {
        slug?: string;
        data: AccountInfoFormData | FormData;
      }
    >({
      invalidatesTags: ["accountInfo", "accountInfoByUser"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `mode_receptions/${slug}/`,
            method: "PUT",
            body: data,
          };
        }

        return {
          url: `mode_receptions/`,
          method: "POST",
          body: data,
        };
      },
    }),

    deleteAccountInfo: builder.mutation<AccountInfo, string>({
      query: (slug) => ({
        url: `mode_receptions/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["accountInfo", "accountInfoByUser"],
    }),
    getAccountInfoByUser: builder.query<
      PaginationResults<AccountInfo>,
      TypeQuery
    >({
      query: ({ slug, type, ...query }) =>
        QueryUrl(
          type === "agence"
            ? `agence/${slug}/mode_reception`
            : `proprietaire/${slug}/mode_reception`,
          query
        ),
      providesTags: ["accountInfoByUser"],
    }),
  }),
});

export const {
  useAccountInfoListQuery,
  useAddOrUpdateAccountInfoMutation,
  useDeleteAccountInfoMutation,
  useGetAccountInfoByUserQuery,
} = AccountInfoApi;
