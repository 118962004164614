import React from "react";

const EnMaintenance = () => {
  return (
    <div className="d-flex justify-content-center align-items-center text-center">
      <h1 className="">Nous sommes actuellement en maintenance</h1>
      <p className="text-black ">
        Pour plus d'informations, merci de nous contacter sur{" "}
        <a className="no-link" href={`mailto:contact@kanimmo.com`}>
          contact@kanimmo.com
        </a>
      </p>
    </div>
  );
};

export default EnMaintenance;
