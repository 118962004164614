import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { IAgence } from "../agence/agence.type";
import { DepenseFormData, IDepense } from "../depense/depense.type";
import { EntreeFormData, IEntree } from "../entree/entree.type";
import { ILocataire } from "../locataire/locataire.type";
import { UserApi, prepareHeaders } from "../user/user.api";
import {
  AuthState,
  IPQGSBData,
  IUser,
  PaginationResults,
  TypeQuery,
} from "../user/user.type";
import {
  IProprietaire,
  ProprietaireFormData,
  DasboardData,
  IComptaPQGSB,
  PaginationItems,
  Gerance,
  CompteRendu,
} from "./proprietaire.type";

export const ProprietaireApi = createApi({
  reducerPath: "ProprietaireAPi",
  tagTypes: [
    "proprietaire",
    "propriete_by_agence",
    "proprietaire_user",
    "proprietaire_locataire",
    "auth",
    "proprietaire_agences",
    "proprietaire_notifications",
    "proprietaire_dashboard",
    "comptabilite_pqgsb",
    "paymentProprietaire",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    registerProprietaire: builder.mutation<
      AuthState["user"],
      ProprietaireFormData | FormData
    >({
      query: (data) => ({
        url: "proprietaire/inscriptions/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      // transformResponse: ({ data }) => data,
    }),
    getProprietaire: builder.query<PaginationResults<IProprietaire>, TypeQuery>(
      {
        query: (query) => ({
          url: QueryUrl("proprietaires/", query),
          method: "GET",
        }),
        providesTags: ["proprietaire"],
      }
    ),
    addOrUpdateProprietaire: builder.mutation<
      IProprietaire,
      { slug?: string; data: ProprietaireFormData | FormData }
    >({
      invalidatesTags: ["proprietaire", "propriete_by_agence"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `proprietaires/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `proprietaires/`,
          method: "POST",
          body: data,
        };
      },
    }),
    update: builder.mutation<IProprietaire, ProprietaireFormData>({
      invalidatesTags: [
        "proprietaire",
        "propriete_by_agence",
        "proprietaire_user",
      ],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(UserApi.util.invalidateTags(["user"]));
        }, 1500);
      },
      query: ({ slug, ...data }) => {
        return {
          url: `proprietaires/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteProprietaire: builder.mutation<IProprietaire, string>({
      query: (slug) => ({
        url: `proprietaires/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["proprietaire"],
    }),
    getProprietairesByAgence: builder.query<
      PaginationResults<IProprietaire>,
      TypeQuery
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`agence/${slug}/proprietaires/`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getUserPQGSBBySlug: builder.query<IUser[], TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaireqgb/${slug}/users/`, query),
      providesTags: ["proprietaire_user"],
    }),
    getLocataireByProprietaireBySlug: builder.query<
      PaginationResults<ILocataire>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaire/${slug}/locataires/`, query),
      providesTags: ["proprietaire_locataire"],
    }),
    getAgenceByProprietaire: builder.query<
      PaginationResults<IAgence>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaire/${slug}/agencesqgb/`, query),
      providesTags: ["proprietaire_agences"],
    }),
    getInfosByProprietaireqnpgb: builder.query<DasboardData, TypeQuery>({
      query: ({ slug, ...query }) => ({
        url: QueryUrl(`proprietaireqngb/${slug}/dashboard/`, query),
        method: "POST",
      }),
      providesTags: ["proprietaire_dashboard"],
    }),
    addOrUpdateEntreePQGSB: builder.mutation<
      IEntree,
      { slug?: string; data: EntreeFormData | FormData }
    >({
      invalidatesTags: ["comptabilite_pqgsb"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `entrees/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `entrees/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrUpdateDepensePQGSB: builder.mutation<
      IDepense,
      { slug?: string; data: DepenseFormData | FormData }
    >({
      invalidatesTags: ["comptabilite_pqgsb"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `depenses/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `depenses/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getComptabiliteByProprietaireBySlug: builder.query<IComptaPQGSB, TypeQuery>(
      {
        query: ({ slug, ...query }) =>
          QueryUrl(`proprietaire/${slug}/comptabilite/`, query),
        providesTags: ["comptabilite_pqgsb"],
      }
    ),
    getPQGSBData: builder.query<IPQGSBData, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaire/${slug}/dashboard/`, query),
      providesTags: ["proprietaire_dashboard"],
    }),
    getPaiementProprio: builder.query<PaginationItems<Gerance>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/gerances/`, query),
      providesTags: ["paymentProprietaire"],
    }),

    getCreateCompteRendu: builder.mutation<CompteRendu, string>({
      invalidatesTags: ["paymentProprietaire"],
      query: (slug) => {
        return {
          url: `compte_rendu_gestion/${slug}/`,
          method: "GET",
        };
      },
    }),

    sendPayment: builder.mutation<Gerance, string>({
      invalidatesTags: ["paymentProprietaire"],
      query: (slug) => {
        return {
          url: `agence/${slug}/mypay/`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetProprietaireQuery,
  useAddOrUpdateProprietaireMutation,
  useDeleteProprietaireMutation,
  useGetProprietairesByAgenceQuery,
  useRegisterProprietaireMutation,
  useGetUserPQGSBBySlugQuery,
  useGetLocataireByProprietaireBySlugQuery,
  useGetAgenceByProprietaireQuery,
  useGetInfosByProprietaireqnpgbQuery,
  useGetComptabiliteByProprietaireBySlugQuery,
  useGetPQGSBDataQuery,
  useAddOrUpdateEntreePQGSBMutation,
  useAddOrUpdateDepensePQGSBMutation,
  useGetPaiementProprioQuery,
  useSendPaymentMutation,
  useGetCreateCompteRenduMutation,
} = ProprietaireApi;
