import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IAgenceData,
  IFiltreAgenceData,
  PaginationResults,
  TypeQuery,
} from "../user/user.type";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import {
  IAgence,
  IAgenceFormData,
  IComptaAgence,
  ILocataireAgence,
  IPersonnel,
  IProprieteComptaAgence,
  IUserAgence,
  PersonnelFormData,
} from "./agence.type";
import { prepareHeaders } from "../user/user.api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IPropriete } from "../propriete/propriete.type";

export const AgenceApi = createApi({
  reducerPath: "agenceApi",
  tagTypes: [
    "agence",
    "agence_slug",
    "proprietes_by_agence",
    "personnel_slug",
    "personnel",
    "user_agence",
    "comptabilite_comptable",
    "propriete_compta_agence",
    "locataire_agence",
    "dashboard",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getAgences: build.query<PaginationResults<IAgence>, TypeQuery>({
      query: (query) => QueryUrl("agences/", query),
      providesTags: ["agence"],
    }),
    getProprieteByAgenceBySlug: build.query<IAgence[], TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`agences/${slug}`, query),
      transformResponse: ({ results }) => results,
      providesTags: ["proprietes_by_agence"],
    }),
    addOrUpdateAgence: build.mutation<
      IAgence,
      { slug?: string; data: IAgenceFormData }
    >({
      invalidatesTags: ["agence"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `agences/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `agences/`,
          method: "POST",
          body: data,
        };
      },
    }),
    agenceBySlug: build.query<IAgence, string>({
      query: (slug) => `agences/${slug}`,
      providesTags: ["proprietes_by_agence"],
    }),
    deleteProprieteByAgence: build.mutation<IAgence, string>({
      query: (slug) => ({
        url: `proprietes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["proprietes_by_agence"],
    }),
    addOrUpdatePersonnel: build.mutation<
      IPersonnel,
      { slug?: string; data: PersonnelFormData | FormData }
    >({
      invalidatesTags: ["agence", "agence_slug", "personnel"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `personnels/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `personnels/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deletePersonnel: build.mutation<PersonnelFormData, string>({
      query: (slug) => ({
        url: `personnels/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["agence", "agence_slug", "personnel_slug"],
    }),
    getPersonnelBySlug: build.query<IPersonnel | any, TypeQuery>({
      query: (slug) => `personnels/${slug}`,
      providesTags: ["personnel_slug"],
    }),
    getPersonnel: build.query<PaginationResults<IPersonnel>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`personnels`, query),
      providesTags: ["agence", "personnel"],
    }),
    getUserByAgenceBySlug: build.query<IUserAgence[], TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`agence/${slug}/users/`, query),
      providesTags: ["user_agence"],
    }),
    getPersonnelByAgenceBySlug: build.query<
      PaginationResults<IPersonnel | IUserAgence>,
      TypeQuery
    >({
      query: ({ slug, type, ...query }) =>
        QueryUrl(
          `${
            type !== "proprietaire" ? "agence" : "proprietaire"
          }/${slug}/personnels/`,
          query
        ),
      providesTags: ["personnel"],
    }),
    getPersonnelByProprietaireBySlug: build.query<
      PaginationResults<IUserAgence>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaire/${slug}/personnels/`, query),
      providesTags: ["personnel"],
    }),
    getComptabiliteByAgenceBySlug: build.query<IComptaAgence, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/comptabilite/`, query),
      providesTags: ["comptabilite_comptable"],
    }),

    getLocataireByAgenceBySlug: build.query<
      PaginationResults<ILocataireAgence>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/locataires/`, query),
      providesTags: ["locataire_agence"],
    }),
    getProprieteComptaByAgenceBySlug: build.query<
      PaginationResults<IPropriete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/proprietes/`, query),
      providesTags: ["propriete_compta_agence"],
    }),
    getAgenceData: build.query<IAgenceData, TypeQuery>({
      query: ({ slug, type, ...query }) => {
        if (type === "agence") {
          return {
            url: QueryUrl(`agence/${slug}/dashboard/`, query),
            method: "POST",
          };
        }
        return {
          url: QueryUrl(`proprietaire/${slug}/dashboard/`, query),
          method: "POST",
        };
      },
      providesTags: ["dashboard"],
    }),
    filtreAgenceData: build.mutation<IFiltreAgenceData, TypeQuery>({
      query: ({ slug, type, data, ...query }) => {
        if (type === "agence") {
          return {
            url: QueryUrl(`agence/${slug}/dashboard/`, query),
            method: "POST",
            body: data,
          };
        } else if (type === "proprietaire") {
          return {
            url: QueryUrl(`proprietaire/${slug}/dashboard/`, query),
            method: "POST",
            body: data,
          };
        } else {
          return {
            url: QueryUrl(`proprietaireqngb/${slug}/dashboard/`, query),
            method: "POST",
            body: data,
          };
        }
      },
      // providesTags: ["dashboard"],
    }),
    deleteAgence: build.mutation<IAgence, string>({
      query: (slug) => ({
        url: `agences/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["agence"],
    }),
  }),
});

export const {
  useGetAgencesQuery,
  useLazyGetAgencesQuery,
  useGetProprieteByAgenceBySlugQuery,
  useAgenceBySlugQuery,
  useAddOrUpdateAgenceMutation,
  useAddOrUpdatePersonnelMutation,
  useDeletePersonnelMutation,
  useGetPersonnelBySlugQuery,
  useGetPersonnelQuery,
  useLazyGetPersonnelBySlugQuery,
  useGetUserByAgenceBySlugQuery,
  useGetComptabiliteByAgenceBySlugQuery,
  useGetPersonnelByAgenceBySlugQuery,
  useGetLocataireByAgenceBySlugQuery,
  useGetProprieteComptaByAgenceBySlugQuery,
  useGetPersonnelByProprietaireBySlugQuery,
  useGetAgenceDataQuery,
  useFiltreAgenceDataMutation,
  useDeleteAgenceMutation,
} = AgenceApi;

export function usePersonnelFromLocation(): [
  IPersonnel,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IPersonnel | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetPersonnelBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/agence/dashboard", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    }
  }, [data]);

  return [item as IPersonnel, isLoading, slug as string, findBySlug as any];
}

export function usePersonnelDetailsFromLocation(): [
  IPersonnel,
  (slug: string) => any
] {
  const itemState = useLocationState<IPersonnel | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
    useLazyGetPersonnelBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      // console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("slug", data);
      setItem(data);
    }
  }, [data]);

  return [item as IPersonnel, findBySlug as any];
}
