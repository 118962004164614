import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { isLocataire, openPopup, QueryUrl } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import {
  IPaiement,
  PaiementFormData,
  PaymentFormData,
  PaymentManuel,
  PaymentManuelFormData,
  PaymentUrl,
} from "./paiement.type";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../redux/hooks";
import { LocataireApi } from "../locataire/locataire.api";

export const PaiementApi = createApi({
  reducerPath: "PaiementApi",
  tagTypes: ["paiements", "paiements_slug", "paiementsByLocataire", "auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getPaymentUrl: build.query<PaymentUrl, IPaiement>({
      query: (query) => QueryUrl(`${ApiBaseUrl}/api/paiements/`, query),
    }),
    getPaiement: build.query<PaginationResults<IPaiement>, TypeQuery>({
      query: (query) => QueryUrl("paiements/", query),
      providesTags: ["paiements"],
    }),
    addOrUpdatePaiement: build.mutation<IPaiement, PaiementFormData>({
      invalidatesTags: ["paiements", "paiements_slug"],
      query: (data) => ({
        url: `paiements/create/`,
        method: "POST",
        body: data,
      }),
    }),
    deletePaiement: build.mutation<PaiementFormData, string>({
      query: (slug) => ({
        url: `paiements/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["paiements", "paiements_slug"],
    }),
    getPaiementByLocataireBySlug: build.query<
      PaginationResults<IPaiement>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataire/${slug}/paiements/`, query),
      providesTags: ["paiementsByLocataire"],
    }),
    getPaiementByAgenceBySlug: build.query<
      PaginationResults<IPaiement>,
      TypeQuery
    >({
      query: ({ slug, type, ...query }) =>
        QueryUrl(
          `${
            type === "agence" ? "agence" : "proprietaireqgb"
          }/${slug}/paiements/`,
          query
        ),
      providesTags: ["paiements"],
    }),
    getPaiementBySlug: build.query<IPaiement | any, TypeQuery>({
      query: (slug) => `paiements/${slug}`,
      providesTags: ["paiements_slug"],
    }),
    createPaiment: build.mutation<IPaiement, PaymentFormData>({
      query: (data) => {
        return {
          url: `/paiements/initiate/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(LocataireApi.util.invalidateTags(["locataireBySlug"]));
        }, 2000);
      },
    }),
    paiementLoyer: build.query<
      { response_text: string; message?: string },
      string
    >({
      query: (slug) => `paiements/${slug}/`,
    }),
    sendPayment: build.mutation<PaymentManuel, PaymentManuelFormData>({
      query: (data) => {
        return {
          url: `paiement_manuels/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [],
    }),
  }),
});

export const {
  useAddOrUpdatePaiementMutation,
  useGetPaiementQuery,
  useDeletePaiementMutation,
  useGetPaiementByLocataireBySlugQuery,
  useGetPaiementByAgenceBySlugQuery,
  useLazyGetPaiementByAgenceBySlugQuery,
  useLazyGetPaiementBySlugQuery,
  useLazyPaiementLoyerQuery,
  useLazyGetPaiementQuery,
  useCreatePaimentMutation,
  useSendPaymentMutation
} = PaiementApi;
