import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IReseauSociaux, ReseauSociauxFormData } from "./reseauSociaux.type";

export const ReseauSociauxApi = createApi({
	reducerPath: "reseauSociaux",
	tagTypes: ["reseauSociaux"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getRSociaux: build.query<PaginationResults<IReseauSociaux>, TypeQuery>({
			query: (query) => QueryUrl(`reseauxsociaux/`, query),
			providesTags: ["reseauSociaux"],
		}),
		getRSociauxByVisiteur: build.query<IReseauSociaux[], void>({
			query: () => `reseauxsociauxbyvisiteur/`,
			providesTags: ["reseauSociaux"],
		}),
		// getHomeSlider: build.query<ISlider[], void>({
		// 	query: () => `sliderswithoutpagination/`,
		// 	providesTags: ["slider"],
		//   }),
		addOrUpdateRSociaux: build.mutation<IReseauSociaux, { slug?: string; data: ReseauSociauxFormData }>({
			invalidatesTags: ["reseauSociaux"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `reseauxsociaux/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `reseauxsociaux/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteRSociaux: build.mutation<IReseauSociaux, string>({
			query: (slug) => ({
				url: `reseauxsociaux/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["reseauSociaux"],
		}),
	}),
});

export const {
	useGetRSociauxQuery,
	useLazyGetRSociauxQuery,
	useDeleteRSociauxMutation,
	useAddOrUpdateRSociauxMutation,
	useGetRSociauxByVisiteurQuery
} = ReseauSociauxApi;