import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ICompteRendu } from "./conterendu.type";

export const CompteRenduAPi = createApi({
  reducerPath: "CompteRenduAPi",
  tagTypes: ["compteRendu",],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({

    getCompteRenduAgence: builder.query<PaginationResults<ICompteRendu>,  string>({
      query: (slug, ...query) => QueryUrl(`compte_rendu_gestion_aagence/${slug}`, query),
      providesTags: ["compteRendu"],
    }),
    getCompteRenduProprietaire: builder.query<PaginationResults<ICompteRendu>,  string>({
      query: (slug, ...query) => QueryUrl(`compte_rendu_gestion_proprietaire/${slug}`, query),
      providesTags: ["compteRendu"],
    }),

  }),
});

export const {
	useGetCompteRenduAgenceQuery,
  useGetCompteRenduProprietaireQuery
} = CompteRenduAPi;