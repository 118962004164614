import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { EtatLieuFormData, IEtatLieu } from "./etatlieu.type";

export const EtatLieuApi = createApi({
	reducerPath: "EtatLieuAPi",
	tagTypes: ["etatLieu", "etatLieuByAgence", "etatLieuByLocataire", "etatLieuByProprietaire"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getEtatLieu: builder.query<PaginationResults<IEtatLieu>, TypeQuery>({
			query: (query) => QueryUrl(`etatlieux/`, query),
			providesTags: ["etatLieu"],
		}),
		getFactureByAgence: builder.query<PaginationResults<IEtatLieu>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`agence/${slug}/etatlieux/`, query),
			providesTags: ["etatLieu"],
		}),
		getEtatLieuByAgence: builder.query<PaginationResults<IEtatLieu>, TypeQuery>({
			query: ({ slug, ...query }) => {
				return QueryUrl(`agence/${slug}/etatlieux/`, query);
			},
			providesTags: ["etatLieuByAgence"],
		}),
		getEtatLieuByLocataire: builder.query<PaginationResults<IEtatLieu>, TypeQuery>({
			query: ({ slug, ...query }) => {
				return QueryUrl(`locataires/${slug}/etatlieux/`, query);
			},
			providesTags: ["etatLieuByLocataire"],
		}),
		getEtatLieuByProprietaireQngpsb: builder.query<PaginationResults<IEtatLieu>, TypeQuery>({
			query: ({ slug, ...query }) => {
				return QueryUrl(`proprietaireqngb/${slug}/etatlieux/`, query);
			},
			providesTags: ["etatLieuByProprietaire"],
		}),
		getEtatLieuByProprietaire: builder.query<PaginationResults<IEtatLieu>, TypeQuery>({
			query: ({ slug, ...query }) => {
				return QueryUrl(`proprietaire/${slug}/etatlieux/`, query);
			},
			providesTags: ["etatLieuByProprietaire"],
		}),
		addOrUpdateEtatLieu: builder.mutation<
			IEtatLieu,
			{ slug?: string; data: EtatLieuFormData | FormData }
		>({
			invalidatesTags: ["etatLieu", "etatLieuByAgence", "etatLieuByLocataire", "etatLieuByProprietaire"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `etatlieux/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `etatlieux/`,
					method: "POST",
					body: data,
				};
			},
		}),
		findEtatLieuBySlug: builder.query<IEtatLieu, string>({
			query: (slug) => `etatlieux/${slug}/`,
		}),
		deleteEtatLieu: builder.mutation<IEtatLieu, string>({
			query: (slug) => ({
				url: `etatlieux/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["etatLieu", "etatLieuByAgence", "etatLieuByLocataire", "etatLieuByProprietaire"],
		}),
	}),
});

export const {
  useGetEtatLieuQuery,
  useAddOrUpdateEtatLieuMutation,
  useDeleteEtatLieuMutation,
  useLazyFindEtatLieuBySlugQuery,
  useGetEtatLieuByAgenceQuery,
  useGetEtatLieuByLocataireQuery,
  useGetEtatLieuByProprietaireQuery,
  useGetEtatLieuByProprietaireQngpsbQuery
} = EtatLieuApi;
