import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import React, { useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { AuthState, PaginationResults, TypeQuery } from "../user/user.type";
import { Chat, ChatData, ChatFormData } from "./chat.type";
const tags = {
	chat: "chat",
} as const;

export const ChatApi = createApi({
	tagTypes: Object.values(tags),
	reducerPath: "chat",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		chatByUser: builder.query<ChatData[], TypeQuery>({
			query: (query) =>
				QueryUrl(
					`user/${AppStorage.getItem<AuthState>("user")?.user?.slug}/messages`,
					query
				),
			providesTags: [tags.chat],
			transformResponse: transformChatToChatData,
		}),

		addChat: builder.mutation<Chat, ChatFormData>({
			query: (data) => ({
				url: `messages/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["chat"],
		}),
		updateCHat: builder.mutation<Chat, ChatFormData>({
			query: ({ slug, ...data }) => ({
				url: `messages/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["chat"],
		}),
	}),
});

export const {
	useAddChatMutation,
	useChatByUserQuery,
	useLazyChatByUserQuery,
	useUpdateCHatMutation,
} = ChatApi;

// export function useChatCount() {
// 	const [count, setCount] = React.useState(0);
// 	const user = useAppSelector((s) => s.user.user);
// 	const [find, { data }] = ChatApi.useLazyChatByUserQuery();
// 	useEffect(() => {
// 		if (user?.id) {
// 			find();
// 		}
// 	}, [user?.id]);

// 	useEffect(() => {
// 		if (data) {
// 			setCount(data.reduce((acc, cur) => acc + cur.count, 0));
// 		}
// 	}, [data]);
// 	return count;
// }

function transformChatToChatData(data: Chat[]): ChatData[] {
	const t = (chat: Chat) => moment(chat.created_at).toDate().getTime();
	const userId = AppStorage.getItem<AuthState>("user")?.user?.id;
	if (!userId) return [];
	const finalData: ChatData[] = [];
	const sorted = [...data].sort((a, b) => t(a) - t(b));
	const usersId = Array.from(
		new Set(
			sorted.map((item) =>
				// @ts-ignore
				item.sender.id === userId ? item.recever.id : item.sender.id
			)
		)
	);

	for (let id of usersId) {
		const messages = sorted.filter((s) => s.sender.id === id || s.recever.id === id);
		const count = messages.filter((f) => f.sender.id === id && !f.read).length;
		const user = messages[0].sender.id === id ? messages[0].sender : messages[0].recever;
		finalData.push({
			lastMessage: messages[messages.length - 1] as Chat,
			messages,
			user,
			count,
		});
	}
	return finalData.sort((a,b) => b?.lastMessage?.id - a?.lastMessage?.id);
}