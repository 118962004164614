// expireataabonnementApi.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { ISubscriber,SubscriberFormaData,Subscriber } from "./subscriber.type";


export const allSubscrberApi = createApi({
  reducerPath: "subscrberApi",
  tagTypes: ["allSubscrber"],   
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    showAllSubscriber: build.query<PaginationResults<ISubscriber>, TypeQuery>({
      query: (query) => QueryUrl("showallsubscriber/", query),
      providesTags: ["allSubscrber"],
    }),
    deleteOneSubscriber: build.mutation<SubscriberFormaData, string>({
        query: (slug) => ({
            url: `deleteonallsubscriber/${slug}`,
            method: "Delete",
        }),
        invalidatesTags: ["allSubscrber"],
    }),
    updateSubscriber: build.mutation<Subscriber, SubscriberFormaData>({
      invalidatesTags: ["allSubscrber"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `updateonallsubscriber/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        throw new Error("Le slug est requis pour la mise à jour de l'abonnement.");
      },
      transformResponse: ({ data }) => data,
    }),

  }),
  

});

export const { useShowAllSubscriberQuery, useDeleteOneSubscriberMutation, useUpdateSubscriberMutation, } = allSubscrberApi;