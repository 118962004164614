import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	IRepresentationJustice,
	RepresentationJusticeFormData,
} from "./representationJustice.type";

export const RepresentationJusticeApi = createApi({
	reducerPath: "RepresentationJusticeAPi",
	tagTypes: ["representationJustice"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getRepresentationJustice: builder.query<
			PaginationResults<IRepresentationJustice>,
			TypeQuery
		>({
			query: (query) => QueryUrl(`representationjustices/`, query),
			providesTags: ["representationJustice"],
		}),
		getRepresentationJusticeByAgence: builder.query<
			PaginationResults<IRepresentationJustice>,
			TypeQuery
		>({
			query: ({ slug, ...query }) =>
				QueryUrl(`agence/${slug}/representationjustices/`, query),
			providesTags: ["representationJustice"],
		}),
		addOrUpdateRepresentationJustice: builder.mutation<
			IRepresentationJustice,
			{ slug?: string; data: RepresentationJusticeFormData | FormData }
		>({
			invalidatesTags: ["representationJustice"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `representationjustices/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `representationjustices/`,
					method: "POST",
					body: data,
				};
			},
		}),
		findRepresentationJusticeBySlug: builder.query<IRepresentationJustice, string>({
			query: (slug) => `representationJustices/${slug}/`,
		}),
		deleteRepresentationJustice: builder.mutation<IRepresentationJustice, string>({
			query: (slug) => ({
				url: `representationjustices/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["representationJustice"],
		}),
	}),
});

export const {
	useGetRepresentationJusticeQuery,
	useAddOrUpdateRepresentationJusticeMutation,
	useDeleteRepresentationJusticeMutation,
	useLazyFindRepresentationJusticeBySlugQuery,
	useGetRepresentationJusticeByAgenceQuery,
} = RepresentationJusticeApi;
