// expireataabonnementApi.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";

export const expireataabonnementApi = createApi({
  reducerPath: "expireatAabonnement",
  tagTypes: ["expireAbonnement"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    expireAbonnement: build.query<{ is_expired: boolean,days_until_expiration: number }, string>({
      query: (slug) => `expireataabonnement/${slug}/`,
      providesTags: ["expireAbonnement"],
    }),

    userCreatePersonelSubscriber: build.query<{ is_expired: boolean, days_until_expiration: number }, string>({
      query: (slug) => `userCreatePersonelSubscriber/${slug}/`,
      providesTags: ["expireAbonnement"],
    }),
    pqngpsbAgenceSubscribe: build.query<{ is_expired: boolean, days_until_expiration: number }, string>({
      query: (slug) => `pqngpsbAgenceSubscribe/${slug}/`,
      providesTags: ["expireAbonnement"],
    }),
    CompareNpPropieteAndMaxOffre: build.query<{ can_add_proprete: boolean,can_add_locataire: boolean }, string>({
      query: (slug) => `CompareNpPropieteAndMaxOffre/${slug}/`,
      providesTags: ["expireAbonnement"],
    }),
  }),
});

export const { useExpireAbonnementQuery, useUserCreatePersonelSubscriberQuery, usePqngpsbAgenceSubscribeQuery, useCompareNpPropieteAndMaxOffreQuery } = expireataabonnementApi;