import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { IRappel, RappelFormData } from "./rappel.type";

export const rappelApi = createApi({
  reducerPath: "rappelApi",
  tagTypes: ["rappels", "rappel_slug", "auth", "rappelsLocataire"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getRappels: build.query<PaginationResults<IRappel>, TypeQuery>({
      query: (query) => QueryUrl("rappels/", query),
      providesTags: ["rappels"],
    }),
    addOrUpdateRappel: build.mutation<
      IRappel,
      { slug?: string; data: RappelFormData | FormData }
    >({
      invalidatesTags: ["rappels", "rappel_slug", "rappelsLocataire"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `rappels/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `rappels/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteRappel: build.mutation<RappelFormData, string>({
      query: (slug) => ({
        url: `rappels/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["rappels", "rappel_slug", "rappelsLocataire"],
    }),
    getRappelByProprietaireBySlug: build.query<
      PaginationResults<IRappel>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaires/${slug}/rappels/`, query),
      providesTags: ["rappels"],
    }),
    getRappelByLocataires: build.query<PaginationResults<IRappel>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataire/${slug}/rappels/`, query),
      providesTags: ["rappelsLocataire"],
    }),
  }),
});

export const {
    useAddOrUpdateRappelMutation,
    useDeleteRappelMutation,
    useGetRappelsQuery,
    useGetRappelByProprietaireBySlugQuery,
    useGetRappelByLocatairesQuery
} = rappelApi