import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IProbleme, TProblemeForm } from "./probleme.type";

export const ProblemeApi = createApi({
	reducerPath: "ProblemeApi",
	tagTypes: ["problemes","problemes_slug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getProblemeMessages: build.query<PaginationResults <IProbleme>, TypeQuery>({
			query: (query) => QueryUrl(`problemes/`, query),
      		providesTags: ["problemes"],
		}),
		addOrUpdateProbleme: build.mutation<IProbleme,{ slug?: string; data: TProblemeForm | FormData }>({
			invalidatesTags: ["problemes", "problemes_slug"],
			query: ({ slug, data }) => {
			if (slug) {
				return {
				url: `problemes/${slug}/`,
				method: "PUT",
				body: data,
				};
			}
			return {
				url: `problemes/`,
				method: "POST",
				body: data,
			};
			},
	  }),
		deleteProbleme: build.mutation<IProbleme, string>({
			query: (slug) => ({
				url: `problemes/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["problemes"],
		}),
		responseProbleme: build.mutation<IProbleme, TProblemeForm>({
			invalidatesTags: ["problemes"],
			query: (data) => {
				return {
					url: `reponseproblemes/`,
					method: "POST",
					body: data,
				};
			},
		}),
		
	}),
});

export const {
	useAddOrUpdateProblemeMutation,
	useGetProblemeMessagesQuery,
	useDeleteProblemeMutation,
	useResponseProblemeMutation
} = ProblemeApi;
