import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { CategoryFormData, ICategory, ICategoryEntree } from "./category.type";

export const CategoryApi = createApi({
  reducerPath: "CategoryAPi",
  tagTypes: ["category", "etatLieuByAgence", "etatLieuByLocataire"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCategory: builder.query<PaginationResults<ICategory>, TypeQuery>({
      query: (query) => QueryUrl(`category/`, query),
      providesTags: ["category"],
    }),
    getCategoryEntree: builder.query<PaginationResults<ICategoryEntree>, TypeQuery>({
      query: (query) => QueryUrl(`categorieentrees/`, query),
      providesTags: ["category"],
    }),
    categorydepensebyuser: builder.query<PaginationResults<ICategoryEntree>, [string, TypeQuery]>({
      query: ([slug, query]) => QueryUrl(`CategoryAPIListViewByUser/${slug}`, query),
      providesTags: ["category"],
    }), 
    categoryentreebyuser: builder.query<PaginationResults<ICategoryEntree>, [string, TypeQuery]>({
      query: ([slug, query]) => QueryUrl(`CategorieShowByUser/${slug}`, query),
      providesTags: ["category"],
    }),  
    addOrUpdateCategory: builder.mutation<
      ICategory,
      { slug?: string; data: CategoryFormData | FormData }
    >({
      invalidatesTags: ["category"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `category/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `category/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrUpdateCategoryEntree: builder.mutation<
      ICategory,
      { slug?: string; data: CategoryFormData | FormData }
    >({
      invalidatesTags: ["category"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `categorieentrees/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `categorieentrees/`,
          method: "POST",
          body: data,
        };
      },
    }),
    findCategoryBySlug: builder.query<ICategory, string>({
      query: (slug) => `category/${slug}/`,
    }),
    findCategoryEntreeBySlug: builder.query<ICategoryEntree, string>({
      query: (slug) => `categorieentrees/${slug}/`,
    }),
    deleteCategory: builder.mutation<ICategory, string>({
      query: (slug) => ({
        url: `category/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["category"],
    }),
    deleteCategoryEntree: builder.mutation<ICategoryEntree, string>({
      query: (slug) => ({
        url: `categorieentrees/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["category"],
    }),

  }),
});

export const {
  useGetCategoryQuery,
  useAddOrUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useLazyFindCategoryBySlugQuery,

  useGetCategoryEntreeQuery,
  useAddOrUpdateCategoryEntreeMutation,
  useDeleteCategoryEntreeMutation,
  useLazyFindCategoryEntreeBySlugQuery,
  useCategoryentreebyuserQuery,
  useCategorydepensebyuserQuery
  
} = CategoryApi;
