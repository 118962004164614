import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ISlider, SliderFormdata } from "./slider.type";

const SliderApi = createApi({
  reducerPath: "sliderApi",
  tagTypes: ["slider"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrUpdateSlider: build.mutation<
      ISlider,
      { slug?: string; data: SliderFormdata | FormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `sliders/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `createsliders/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["slider"],
    }),
    getSlider: build.query<PaginationResults<ISlider>, TypeQuery>({
      query: () => `sliders/`,
      providesTags: ["slider"],
    }),
    getHomeSlider: build.query<ISlider[], void>({
      query: () => `sliderswithoutpagination/`,
      providesTags: ["slider"],
    }),
    deleteSlider: build.mutation<SliderFormdata, string>({
      query: (slug) => ({
        url: `sliders/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["slider"],
    }),
  }),
});

export default SliderApi;

export const {
  useAddOrUpdateSliderMutation,
  useGetSliderQuery,
  useGetHomeSliderQuery,
  useDeleteSliderMutation,
} = SliderApi;
