  import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
  import { ApiBaseUrl } from '../../http';
  import { prepareHeaders } from '../user/user.api';

  export const signatureApi = createApi({
    reducerPath: 'signatureApi',
    tagTypes: ['allsignature'],
    baseQuery: fetchBaseQuery({
      baseUrl: `${ApiBaseUrl}/api/`,
      prepareHeaders,
    }),
  
    endpoints: (builder) => ({
      addSignature: builder.mutation<void, FormData>({ // Utilisez directement FormData
        query: (formData) => ({
          url: `upload-signature/${formData.get('slug')}/`, // Assurez-vous que 'slug' est le bon nom
          method: 'POST',
          body: formData,
        }),
        // ...
      }),
    }),
  });

  export const { useAddSignatureMutation } = signatureApi;
  export default signatureApi;

