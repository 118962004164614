import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { IEntree, EntreeFormData } from "./entree.type";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AgenceApi } from "../agence/agence.api";

export const EntreeApi = createApi({
  reducerPath: "EntreeApi",
  tagTypes: ["entrees", "entrees_slug", "entreesByComptable", "auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getEntree: build.query<PaginationResults<IEntree>, TypeQuery>({
      query: (query) => QueryUrl("entrees/", query),
      providesTags: ["entrees"],
    }),
    addOrUpdateEntree: build.mutation<
      IEntree,
      { slug?: string; data: EntreeFormData | FormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `entrees/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `entrees/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["entrees", "entrees_slug", "entreesByComptable"],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(AgenceApi.util.invalidateTags(["comptabilite_comptable"]));
        }, 2000);
      },
    }),
    deleteEntree: build.mutation<EntreeFormData, string>({
      query: (slug) => ({
        url: `entrees/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["entrees", "entrees_slug", "entreesByComptable"],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(AgenceApi.util.invalidateTags(["comptabilite_comptable"]));
        }, 2000);
      },
    }),
    getEntreeByComptableBySlug: build.query<
      PaginationResults<IEntree>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`comptables/${slug}/entrees/`, query),
      providesTags: ["entreesByComptable"],
    }),
    getEntreeBySlug: build.query<IEntree | any, TypeQuery>({
      query: (slug) => `entrees/${slug}`,
      providesTags: ["entrees_slug"],
    }),
  }),
});

export const {
  useAddOrUpdateEntreeMutation,
  useGetEntreeQuery,
  useDeleteEntreeMutation,
  useGetEntreeByComptableBySlugQuery,
  useLazyGetEntreeByComptableBySlugQuery,
  useLazyGetEntreeBySlugQuery,
} = EntreeApi;
