import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { IPersonnel } from "../agence/agence.type";
import { ChangePasswordData, LoginResult } from "../auth/auth.type";
import {
  AbonnementFormData,
  AuthState,
  IUser,
  Mensuality,
  MensualityFormData,
  PaginationResults,
  SuppressionType,
  SuppressionTypeFromData,
  TypeQuery,
  UserFormData,
  UserType,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }: any) => {
  const token =
    (getState() as { user: LoginResult }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: [
    "user",
    "userList",
    "proprioList",
    "proprioByAgence",
    "personnels",
    "suppression_compteList",
    "suppression_compteBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      transformResponse: ({ data }) => data,
      query: () => {
        return "me/";
      },
    }),
    addOrEditUser: builder.mutation<
      IUser,
      { slug?: string; userType: UserType; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "userList"],
      query: ({ slug, userType, data }) => {
        if (slug) {
          if (userType === "agence") {
            return {
              url: `agences/${slug}/`,
              method: "PUT",
              body: data,
            };
          }

          if (userType === "locataire") {
            return {
              url: `locataires/${slug}/`,
              method: "PUT",
              body: data,
            };
          }

          if (userType === "proprietaire") {
            return {
              url: `proprietaires/${slug}/`,
              method: "PUT",
              body: data,
            };
          }

          if (userType === "comptable") {
            return {
              url: `comptables/${slug}/`,
              method: "PUT",
              body: data,
            };
          }
          if (
            userType === "gerant" ||
            userType === "marketeur" ||
            userType === "juriste"
          ) {
            return {
              url: `personnels/${slug}/`,
              method: "PUT",
              body: data,
            };
          }

          return {
            url: `admins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getUsersProprioByAgence: builder.query<IUser, string>({
      providesTags: ["proprioByAgence"],
      query: (slug) => `agences/${slug}/proprietaire/`,
    }),
    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `users/changepassword/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getPersonnels: builder.query<PaginationResults<IPersonnel>, TypeQuery>({
      query: (query) => QueryUrl("personnels/", query),
      providesTags: ["personnels"],
    }),
    deletedUsersList: builder.query<
      PaginationResults<SuppressionType>,
      TypeQuery
    >({
      query: (query) => QueryUrl("user/suppression_comptes/", query),
      providesTags: ["suppression_compteList"],
    }),
    addOrUpdateDeletedUser: builder.mutation<
      SuppressionType,
      SuppressionTypeFromData
    >({
      invalidatesTags: [
        "user",
        "userList",
        "suppression_compteList",
        "suppression_compteBySlug",
      ],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `user/${slug}/demande_suppression/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/suppression_comptes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deletedUserBySlug: builder.query<SuppressionType, string>({
      query: (slug) => `user/${slug}/demande_suppression/`,
      providesTags: ["suppression_compteBySlug"],
    }),
    generatePassword: builder.mutation<any, string>({
      query: (slug) => ({
        url: `user/${slug}/generate_password/`,
        method: "GET",
      }),
    }),
    changeOffer: builder.mutation<
      AbonnementFormData,
      AbonnementFormData | FormData
    >({
      query: (data) => ({
        url: `abonnement/change/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    payOffer: builder.mutation<Mensuality, MensualityFormData>({
      query: (data) => ({
        url: `mensualites_offres/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    createAbonnement: builder.mutation<
      AbonnementFormData,
      AbonnementFormData
    >({
      query: (data) => ({
        url: `abonnement/gratuit/?offre=${data?.offre}&user=${data?.user}`,
        method: "GET",
        // body: data,
      }),
      // invalidatesTags: ["user"],
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useAddOrEditUserMutation,
  useGetUsersProprioByAgenceQuery,
  useChangePasswordMutation,
  useGetPersonnelsQuery,
  useAddOrUpdateDeletedUserMutation,
  useDeletedUsersListQuery,
  useLazyDeletedUserBySlugQuery,
  useGeneratePasswordMutation,
  useChangeOfferMutation,
  usePayOfferMutation,
  useCreateAbonnementMutation
} = UserApi;

export function useRequestFromLocation(): [
  SuppressionType,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<SuppressionType | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyDeletedUserBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/admin/mes-clients", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log(data);
      setItem(data);
    }
  }, [data]);

  return [item as SuppressionType, isLoading, slug as string, findBySlug];
}
