import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { INotification ,NotReadType} from "../notification/notification.type";
import { prepareHeaders } from "../user/user.api";
import { NotificationFormData } from "./notification.type";
import {
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
} from "../user/user.type";

export const NotificationApi = createApi({
  tagTypes: ["proprietaire_notifications"],
  reducerPath: "NotificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    updateReadNotification: builder.mutation<
      INotification,
      NotificationFormData
    >({
      query: ({ slug, ...data }) => ({
        url: `notifications/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["proprietaire_notifications"],
    }),
    getNotificationByProprietaire: builder.query<
      PaginationResults<INotification>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`receiver/${slug}/notifications/`, query),
      providesTags: ["proprietaire_notifications"],
    }),


    notificationNotRead: builder.query<NotReadType, { slug: string }>({
      query: ({ slug }) => ({
        url: `notificationNotReadall/${slug}/`,
        method: "GET",
      }),
      providesTags: ["proprietaire_notifications"],
    }),
    

    markAllAsRead: builder.mutation<void, { modelType: string; slug: string }>({
      query: ({ modelType, slug }) => ({
        url: `mark_all_as_read/${modelType}/${slug}/`,
        method: 'POST',
      }),
    }),
    markAsReadNotification: builder.mutation<void, string>({
      query: (slug) => ({
        url: `notificationNotRead/${slug}/`,
        method: "PUT",
      }),
      invalidatesTags: ["proprietaire_notifications"],
    }),

  }),
});

export const {
  useGetNotificationByProprietaireQuery,
  useUpdateReadNotificationMutation,
  useNotificationNotReadQuery,
  useMarkAllAsReadMutation,
  useMarkAsReadNotificationMutation
} = NotificationApi;
