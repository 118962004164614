import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { ImmeublesApi } from "../immeubles/immeubles.api";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  IPropriete,
  ProprieteFormData,
  TypeGerance,
  TypePropriete,
} from "./propriete.type";

export const ProprieteApi = createApi({
  reducerPath: "ProprieteAPi",
  tagTypes: [
    "propriete",
    "proprieteBySlug",
    "propriete_by_agence",
    "propriete_by_proprietaire",
    "appartmentByPropriete",
    "appartmentBySlug",
    "immeublesByUser",
    "PQGSB",
    "PQGPSB",
    "proprieteByAgence",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getPropriete: builder.query<
      PaginationResults<IPropriete>,
      { page: number; word: string }
    >({
      query: (query) => QueryUrl(`proprietes/`, query),
      providesTags: ["propriete"],
    }),
    getProprietesByAgence: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`agence/${slug}/proprietes/`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getProprietesProprioByAgence: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery
    >({
      query: ({ slug_proprio, slug_agence, ...query }) => {
        return QueryUrl(`proprietaireqngb/${slug_proprio}/${slug_agence}/proprietebyagence`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getBienByAgence: builder.query<PaginationResults<IPropriete>, TypeQuery>({
      query: ({ slug, ...query }) => {
        return QueryUrl(`agence/${slug}/biens/`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getProprietesByProprietaire: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaire/${slug}/proprietes/`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getBiensByProprietaire: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaireqgb/${slug}/biens`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getBiensByProprietaireQNGPSB: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaireqngb/${slug}/biens`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    getImmeublesByProprietaireQNGPSB: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaireqngb/${slug}/immeubles`, query);
      },
      providesTags: ["PQGPSB"],
    }),
    getImmeublesByProprietaireQGSB: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaireqgb/${slug}/immeubles`, query);
      },
      providesTags: ["PQGPSB"],
    }),
    getImmeublesByAgence: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`agence/${slug}/immeubles`, query);
      },
      providesTags: ["proprieteByAgence"],
    }),

    getProprietesByProprietaireGPA: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery & {
        type_gerance?: string | TypeGerance | null;
        type_propriete?: string | TypePropriete | null;
      }
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaireqngb/${slug}/proprietes`, query);
      },
      providesTags: ["propriete_by_agence"],
    }),
    addOrUpdatePropriete: builder.mutation<
      IPropriete,
      { slug?: string; data: ProprieteFormData | FormData }
    >({
      invalidatesTags: [
        "propriete",
        "propriete_by_agence",
        "propriete_by_proprietaire",
        "appartmentByPropriete",
        "appartmentBySlug",
        "proprieteBySlug",
        "proprieteByAgence",
      ],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `proprietes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `proprietes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    findProprieteBySlug: builder.query<IPropriete, string>({
      query: (slug) => `proprietes/${slug}/`,
      providesTags: ["proprieteBySlug"],
    }),
    deletePropriete: builder.mutation<IPropriete, string>({
      query: (slug) => ({
        url: `proprietes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "propriete",
        "propriete_by_agence",
        "propriete_by_proprietaire",
        "appartmentByPropriete",
        "proprieteBySlug",
        "immeublesByUser",
        "PQGSB",
        "PQGPSB",
        "proprieteByAgence",
      ],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(ImmeublesApi.util.invalidateTags(["immeublesByUser"]));
      },
    }),
    getAppartmentByPropriete: builder.query<
      PaginationResults<IPropriete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`immeuble/${slug}/appartements`, query);
      },
      providesTags: ["appartmentByPropriete"],
    }),
    deletePicture: builder.mutation<
      { slug: string; image: string; id: number },
      string
    >({
      query: (slug) => ({
        url: `images/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "propriete",
        "propriete_by_agence",
        "propriete_by_proprietaire",
        "appartmentByPropriete",
        "proprieteBySlug",
      ],
    }),
    getImmeublesByUser: builder.query<PaginationResults<IPropriete>, TypeQuery>(
      {
        query: ({ slug, ...query }) => {
          return QueryUrl(`user/${slug}/immeubles/`, query);
        },
        providesTags: ["immeublesByUser"],
      }
    ),
    getProprieteProprietaireWithoutImmeuble: builder.query<IPropriete[], TypeQuery>(
      {
        query: ({ slug, ...query }) => {
          return QueryUrl(`proprietaire/${slug}/proprietes_without_immeubles/`, query);
        },
        providesTags: ["immeublesByUser"],
      }
    ),
  }),
});

export const {
  useGetProprieteQuery,
  useAddOrUpdateProprieteMutation,
  useDeleteProprieteMutation,
  useFindProprieteBySlugQuery,
  useLazyFindProprieteBySlugQuery,
  useGetProprietesByAgenceQuery,
  useGetProprietesByProprietaireQuery,
  useGetProprietesByProprietaireGPAQuery,
  useGetBienByAgenceQuery,
  useLazyGetAppartmentByProprieteQuery,
  useDeletePictureMutation,
  useGetBiensByProprietaireQuery,
  useGetBiensByProprietaireQNGPSBQuery,
  useGetImmeublesByUserQuery,
  useGetImmeublesByProprietaireQNGPSBQuery,
  useGetImmeublesByProprietaireQGSBQuery,
  useGetImmeublesByAgenceQuery,
  useGetProprietesProprioByAgenceQuery,
  useGetAppartmentByProprieteQuery,
  useGetProprieteProprietaireWithoutImmeubleQuery
} = ProprieteApi;
export function useProprieteFromLocation(): [
  IPropriete,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IPropriete | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyFindProprieteBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug || itemState?.slug) {
      findBySlug(slug as string);
    }
  }, [slug, itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    // console.log(data, itemState);
      setItem(data);
    }
  }, [data]);

  return [item as IPropriete, isLoading, slug as string, findBySlug];
}
