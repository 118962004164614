import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IImmeubles, ImmeublesFormData } from "./immeubles.type";

export const ImmeublesApi = createApi({
  reducerPath: "immeubles",
  tagTypes: ["immeubles", "immeublesByUser"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getImmeubles: build.query<PaginationResults<IImmeubles>, TypeQuery>({
      query: (query) => QueryUrl(`immeubles/`, query),
      providesTags: ["immeubles"],
    }),
    getImmeublesByUser: build.query<PaginationResults<IImmeubles>, TypeQuery>({
      query: ({ slug, ...query }) => {
        return QueryUrl(`user/${slug}/immeubles/`, query);
      },
      providesTags: ["immeublesByUser"],
    }),

    addOrUpdateImmeubles: build.mutation<
      IImmeubles,
      { slug?: string; data: ImmeublesFormData | FormData }
    >({
      invalidatesTags: ["immeubles", "immeublesByUser"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `proprietes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `proprietes/`,
          method: "POST",
          body: data,
        };
      },
    }),

    // addOrUpdateImmeubles: build.mutation<IImmeubles, { slug?: string; data: ImmeublesFormData }>({
    // 	invalidatesTags: ["immeubles"],
    // 	query: ({ slug, data }) => {
    // 		if (slug) {
    // 			return {
    // 				url: `immeubles/${slug}/`,
    // 				method: "PUT",
    // 				body: data,
    // 			};
    // 		}
    // 		return {
    // 			url: `immeubles/`,
    // 			method: "POST",
    // 			body: data,
    // 		};
    // 	},
    // 	transformResponse: ({ data }) => data,
    // }),
    deleteImmeubles: build.mutation<IImmeubles, string>({
      query: (slug) => ({
        url: `immeubles/${slug}`,
        method: "Delete",
      }),
      invalidatesTags: ["immeubles", "immeublesByUser"],
    }),
  }),
});

export const {
  useGetImmeublesQuery,
  useLazyGetImmeublesQuery,
  useDeleteImmeublesMutation,
  useAddOrUpdateImmeublesMutation,
  useGetImmeublesByUserQuery,
} = ImmeublesApi;
