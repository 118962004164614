import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import {
  ChangeStatusRequeteType,
  IRequete,
  RequeteFormData,
} from "./requete.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const requeteApi = createApi({
  reducerPath: "requeteApi",
  tagTypes: [
    "requetes",
    "requete_slug",
    "typerequetes",
    "auth",
    "requetes_slug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getRequetes: build.query<PaginationResults<IRequete>, TypeQuery>({
      query: (query) => QueryUrl("requetes/", query),
      providesTags: ["requetes"],
    }),
    getRequetesRegler: build.query<PaginationResults<IRequete>, TypeQuery>({
      query: (query) => QueryUrl("requetes/regler", query),
      providesTags: ["requetes"],
    }),
    addOrUpdateRequete: build.mutation<
      IRequete,
      { slug?: string; data: RequeteFormData | FormData }
    >({
      invalidatesTags: ["requetes", "requete_slug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `requetes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `requetes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    editRequete: build.mutation<
      IRequete,
      { slug?: string; data: RequeteFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: `requetes/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["requetes", "requetes_slug"],
    }),
    deleteRequete: build.mutation<RequeteFormData, string>({
      query: (slug) => ({
        url: `requetes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["requetes", "requete_slug"],
    }),
    getTypeRequetes: build.query<PaginationResults<IRequete>, TypeQuery>({
      query: (query) => QueryUrl("typerequetes/", query),
      providesTags: ["requetes"],
    }),
    getRequeteByLocataireBySlug: build.query<
      PaginationResults<IRequete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataire/${slug}/requetes/`, query),
      providesTags: ["requetes"],
    }),
    getRequeteReglerByLocataireBySlug: build.query<
      PaginationResults<IRequete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataire/${slug}/requetes_regler/`, query),
      providesTags: ["requetes"],
    }),
    getRequeteByAgenceBySlug: build.query<
      PaginationResults<IRequete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/requetes/`, query),
      providesTags: ["requetes"],
    }),
    getRequeteByProprietaireBySlug: build.query<
      PaginationResults<IRequete>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaire/${slug}/requetes/`, query),
      providesTags: ["requetes"],
    }),
    getRequeteBySlug: build.query<IRequete | any, TypeQuery>({
      query: (slug) => `requetes/${slug}`,
      providesTags: ["requete_slug"],
    }),
  }),
});

export const {
  useAddOrUpdateRequeteMutation,
  useGetRequetesQuery,
  useGetRequetesReglerQuery,
  useDeleteRequeteMutation,
  useGetTypeRequetesQuery,
  useGetRequeteByLocataireBySlugQuery,
  useLazyGetRequeteByLocataireBySlugQuery,
  useGetRequeteByAgenceBySlugQuery,
  useLazyGetRequeteByAgenceBySlugQuery,
  useLazyGetRequeteBySlugQuery,
  useEditRequeteMutation,
  useGetRequeteReglerByLocataireBySlugQuery,
  useGetRequeteByProprietaireBySlugQuery
} = requeteApi;

export function useRequeteFromLocation(): [
  IRequete,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IRequete | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetRequeteByLocataireBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/locataire/mon-loyer", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    }
  }, [data]);

  return [item as IRequete, isLoading, slug as string, findBySlug as any];
}

export function useRequeteDetailsFromLocation(): [
  IRequete,
  (slug: string) => any
] {
  const itemState = useLocationState<IRequete | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
  useLazyGetRequeteBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
    // console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    // console.log("slug", data);
      setItem(data as any);
    }
  }, [data]);

  return [item as IRequete, findBySlug as any];
}

export function useRequeteAgenceFromLocation(): [
  IRequete,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IRequete | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetRequeteByAgenceBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/agence/dashboard", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    // console.log("data data", data);
    }
  }, [data]);

  return [item as IRequete, isLoading, slug as string, findBySlug as any];
}

export function useRequeteDetailsAgenceFromLocation(): [
  IRequete,
  (slug: string) => any
] {
  const itemState = useLocationState<IRequete | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
    useLazyGetRequeteBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
    // console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    // console.log("slug", data);
      setItem(data as any);
    }
  }, [data]);

  return [item as IRequete, findBySlug as any];
}
