import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { IDepense, DepenseFormData } from "./depense.type";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AgenceApi } from "../agence/agence.api";

export const DepenseApi = createApi({
  reducerPath: "DepenseApi",
  tagTypes: ["depenses", "depenses_slug", "depensesByComptable", "auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getDepense: build.query<PaginationResults<IDepense>, TypeQuery>({
      query: (query) => QueryUrl("depenses/", query),
      providesTags: ["depenses"],
    }),
    addOrUpdateDepense: build.mutation<
      IDepense,
      { slug?: string; data: DepenseFormData | FormData }
    >({
      invalidatesTags: ["depenses", "depenses_slug", "depensesByComptable"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `depenses/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `depenses/`,
          method: "POST",
          body: data,
        };
      },
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(AgenceApi.util.invalidateTags(["comptabilite_comptable"]));
        }, 2000);
      },
    }),
    deleteDepense: build.mutation<DepenseFormData, string>({
      query: (slug) => ({
        url: `depenses/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["depenses", "depenses_slug", "depensesByComptable"],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(AgenceApi.util.invalidateTags(["comptabilite_comptable"]));
        }, 2000);
      },
    }),
    getDepenseByComptableBySlug: build.query<
      PaginationResults<IDepense>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`comptables/${slug}/depenses/`, query),
      providesTags: ["depensesByComptable"],
    }),
    getDepenseBySlug: build.query<IDepense | any, TypeQuery>({
      query: (slug) => `depenses/${slug}`,
      providesTags: ["depenses_slug"],
    }),
  }),
});

export const {
  useAddOrUpdateDepenseMutation,
  useGetDepenseQuery,
  useDeleteDepenseMutation,
  useGetDepenseByComptableBySlugQuery,
  useLazyGetDepenseByComptableBySlugQuery,
  useLazyGetDepenseBySlugQuery,
} = DepenseApi;

export function useDetailsDepenseComptaFromLocation(): [
  IDepense,
  (slug: string) => any
] {
  const itemState = useLocationState<IDepense | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
    useLazyGetDepenseBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
    // console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    // console.log("slug", data);
      setItem(data as any);
    }
  }, [data]);

  return [item as IDepense, findBySlug as any];
}
