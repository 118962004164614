/* eslint-disable @typescript-eslint/no-explicit-any */
import $ from "jquery";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Avatar1 from "../assets/appImages/avatar1.png";
import Avatar from "../assets/appImages/image-default.png";
import Avatars from "../assets/appImages/Avatars.png";
import defaultImage from "../assets/appImages/NoImage.png";
import { ApiBaseUrl } from "./http";

const phoneUtil = PhoneNumberUtil.getInstance();

export function getInTechPhone(phone: string) {
  const number = phoneUtil.parse(phone, "SN");
  if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
    return number.getNationalNumber();
  }
  console.log("phone", phone);
  return phone;
}
export function getInTechTel(phone: string) {
  const number = phoneUtil.parse(phone, "SN");
  if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
    return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
  }
  return phone;
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export function isLocataire(user: any) {
  if (!user) return false;
  return ["locataire"].includes(user.user_type);
}

export function isProprietaireAgence(user: any) {
  if (!user) return false;

  return (
    (["proprietaire"].includes(user.user_type) &&
      user?.gerer_par_moi === false &&
      !user?.est_gerer_par_proprio_agence) ||
    (["proprietaire"].includes(user.user_type) &&
      user?.gerer_par_moi &&
      user?.est_gerer_par_proprio_agence)
  );
}
export function isProprietaireOwner(user: any) {
  if (!user) return false;
  return (
    (["proprietaire"].includes(user.user_type) &&
      user?.gerer_par_moi &&
      !user?.est_gerer_par_proprio_agence) ||
    (["proprietaire"].includes(user.user_type) &&
      !user?.gerer_par_moi &&
      user?.est_gerer_par_proprio_agence)
  );
}

export function isAgence(user: any) {
  if (!user) return false;
  return ["agence"].includes(user.user_type);
  //  || ["gerant"].includes(user.user_type)
}

export function isAgenceAgent(user: any) {
  if (!user) return false;
  return ["gerant"].includes(user.user_type) && user?.agence_id;
}
export function isAgenceJuriste(user: any) {
  if (!user) return false;
  return ["juriste"].includes(user.user_type) && user?.agence_id;
}

export function isProprioJuriste(user: any) {
  if (!user) return false;
  return ["juriste"].includes(user?.user_type) && user?.proprietaire_id;
}
export function isAgenceMarketeur(user: any) {
  if (!user) return false;
  return ["marketeur"].includes(user.user_type) && user?.agence_id;
}

export function isComptable(user: any) {
  if (!user) return false;
  return ["comptable"].includes(user.user_type);
}

export function formattedDate(date: string | Date | undefined) {
  return date ? moment(date).format("DD/MM/YYYY") : "";
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}

export const getImage = (img: string | any) => {
  if (img && !img.includes("default.png") && !img.includes("static/media/")) {
    if (img.indexOf("http") === 0) return img;
    return ApiBaseUrl + img;
  }
  return defaultImage;
};

export const getFile = (file: string | any) => {
  if (
    file &&
    !file.includes("default.png") &&
    !file.includes("static/media/")
  ) {
    if (file.indexOf("http") === 0) return file;
    return ApiBaseUrl + file;
  }
  return "";
};

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return ApiBaseUrl + avatar;
  }
  return Avatars;
};

export function getAvatarFromUser(user: any) {
  const av1 = getAvatar(user.avatar);
  if (av1 === Avatar) return getAvatar(user.profil.photo);
  return av1;
}

export const getImageProfil = (avatar: string | any) => {
  if (avatar && !avatar.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return ApiBaseUrl + avatar;
  }
  return Avatar1;
};

export function createMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}
export function readMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  let content = text ? text.replace(/<.+?>/g, "") : text;
  return {
    __html: `${numberSlice ? content.slice(0, numberSlice) : content}${
      showDots === true ? "..." : ""
    }`,
  };
}
export function createUrl(image: File) {
  return URL.createObjectURL(image);
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export const onShow = (modalId: string) => {
  document.getElementById(modalId)?.classList.add("show");
  $(`#${modalId}`).show();
};

export const onHide = (modalId: string) => {
  // document.getElementById(modalId)?.classList.remove("show");
  $(".modal-backdrop").remove();
  $(`#${modalId}`).hide();
  $(`#${modalId}`).click();
  $(`body`).css({ overflow: "scroll" });
  setTimeout(() => {
    $(`body`).css({ overflow: "scroll" });
  }, 1000);
};

export const onHideSideBar = () => {
  $(".text-reset").click();
};

export function clean(obj: any) {
  for (var propName in obj) {
    if (
      !obj[propName] ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function checkExtension(filename: string) {
  const extensions = ["jpeg", "jpg", "gif", "png"];
  const fileExtension = filename.split(".").pop();

  if (fileExtension && extensions.includes(fileExtension)) {
    return fileExtension;
  }

  return null;
}

export const formatMontant = (num?: number | string) => {
  return formatAmount(num);
};

export function formatAmount(amount?: number | string, replace = "FCFA") {
  if (amount || amount === 0)
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "CFA",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })
      .format(parseFloat(String(amount)) || 0)
      .replace("CFA", replace);

  return "-";
}

export function getName(item: any) {
  if (item) {
    return item?.prenom + " " + item?.nom;
  }
  return null;
}

export const formatPlurial = (item: number = 0, label?: string) => {
  if (item > 1) {
    return `${item} ${label}s`;
  }
  return `${item} ${label}`;
};

export function openPopup(link: string) {
  return window.open(link, "_self", "popup=yes");
}

export function get_url_extension(uri: any) {
  console.log("pdf url", uri);
  return uri?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
}

export const showModal = (id: string) => {
  // console.log("id", id);
  const element = document.getElementById(id);

  const myModal = new Modal(element);
  myModal.show();
};

export const closeModal = (id: string) => {
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "auto");
  myModal?.hide();
  $(".modal-backdrop").remove();
};
