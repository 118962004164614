import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ILitiges, LitigesFormData } from "./litiges.type";
import { AgenceApi } from "../agence/agence.api";

export const LitigesApi = createApi({
  reducerPath: "LitigesAPi",
  tagTypes: [
    "litiges",
    "litigeByProprietaire",
    "litigeBySlug",
    "litigeByLocataire",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getLitiges: builder.query<PaginationResults<ILitiges>, TypeQuery>({
      query: (query) => QueryUrl(`litiges/`, query),
      providesTags: ["litiges"],
    }),
    addOrUpdateLitige: builder.mutation<
      ILitiges,
      { slug?: string; data: LitigesFormData | FormData }
    >({
      invalidatesTags: ["litiges", "litigeBySlug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `litiges/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `litiges/`,
          method: "POST",
          body: data,
        };
      },
    }),
    editLitige: builder.mutation<
      ILitiges,
      { slug?: string; data: LitigesFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: `litiges/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["litiges", "litigeBySlug"],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(AgenceApi.util.invalidateTags(["dashboard"]));
        }, 1000);
      },
    }),
    findLitigeBySlug: builder.query<ILitiges, string>({
      query: (slug) => `litiges/${slug}/`,
      providesTags: ["litigeBySlug"],
    }),
    deleteLitige: builder.mutation<ILitiges, string>({
      query: (slug) => ({
        url: `litiges/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["litiges", "litigeBySlug"],
    }),
    getLitigeByAgenceBySlug: builder.query<
      PaginationResults<ILitiges>,
      TypeQuery
    >({
      query: ({ slug, ...query }) => QueryUrl(`agence/${slug}/litiges/`, query),
      providesTags: ["litiges"],
    }),
    getLitigeByLocataire: builder.query<PaginationResults<ILitiges>, TypeQuery>(
      {
        query: ({ slug, ...query }) => {
          return QueryUrl(`locataires/${slug}/litiges/`, query);
        },
        providesTags: ["litigeByLocataire"],
      }
    ),
    getLitigeByProprietaire: builder.query<
      PaginationResults<ILitiges>,
      TypeQuery
    >({
      query: ({ slug, ...query }) => {
        return QueryUrl(`proprietaireqngb/${slug}/litiges/`, query);
      },
      providesTags: ["litigeByProprietaire"],
    }),
    getLitigeByProprietaireBySlug: builder.query<
      PaginationResults<ILitiges>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaires/${slug}/litiges/`, query),
      providesTags: ["litiges"],
    }),
  }),
});

export const {
  useAddOrUpdateLitigeMutation,
  useDeleteLitigeMutation,
  useFindLitigeBySlugQuery,
  useGetLitigesQuery,
  useLazyFindLitigeBySlugQuery,
  useLazyGetLitigesQuery,
  useEditLitigeMutation,
  useGetLitigeByAgenceBySlugQuery,
  useGetLitigeByProprietaireQuery,
  useGetLitigeByProprietaireBySlugQuery,
  useGetLitigeByLocataireQuery
} = LitigesApi;

export function useLitigeFromLocation(): [
  ILitiges,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<ILitiges | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyFindLitigeBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug || itemState?.slug) {
      findBySlug(slug as string);
    }
  }, [slug, itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    console.log(data, itemState);
      setItem(data);
    }
  }, [data]);

  return [item as ILitiges, isLoading, slug as string, findBySlug];
}