import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { AnnonceFormData, IAnnonce } from "./annonce.type";
import { prepareHeaders } from "../user/user.api";

export const AnnonceApi = createApi({
	reducerPath: "annonceApi",
	tagTypes: ["annonces", "annonce_slug", "auth"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getAnnonces: build.query<PaginationResults<IAnnonce>, TypeQuery>({
			query: (query) => QueryUrl("annonces/", query),
			providesTags: ["annonces"],
		}),
		getAnnoncesByLocataire: build.query<PaginationResults<IAnnonce>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`locataire/${slug}/notifications/`, query),
			providesTags: ["annonces"],
		}),
		addOrUpdateAnnonce: build.mutation<
			IAnnonce,
			{ slug?: string; data: AnnonceFormData | FormData }
		>({
			invalidatesTags: ["annonces", "annonce_slug"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `annonces/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `annonces/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteAnnonce: build.mutation<AnnonceFormData, string>({
			query: (slug) => ({
				url: `annonces/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["annonces", "annonce_slug"],
		}),
		getAnnoncesByUserBySlug: build.query<PaginationResults<IAnnonce>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`users/${slug}/annonces/`, query),
			providesTags: ["annonces"],
		}),
	}),
});

export const {
	useAddOrUpdateAnnonceMutation,
	useDeleteAnnonceMutation,
	useGetAnnoncesQuery,
	useGetAnnoncesByUserBySlugQuery,
	useGetAnnoncesByLocataireQuery
} = AnnonceApi;
