import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { onSetUser } from "../../../redux/slice/User.slice";
import { ApiBaseUrl } from "../../http";
import { IAdmin, IAdminFormData, IAdminRecette } from "./admin.type";
import { prepareHeaders } from "../user/user.api";
import { IAdminData, IAdminProprietaireData, IUser, PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const AdminApi = createApi({
  reducerPath: "adminApi",
  tagTypes: [
    "admin",
    "adminList",
    "userList",
    "admin_dashboard",
    "admin_recette",
    "admin_proprietaire_data",
    "admin_agence_data",
    "admin_locataires_data"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    meAdmin: builder.query<IAdmin, void>({
      query: () => "meadmin/",
    }),
    adminsList: builder.query<PaginationResults<IAdmin>, TypeQuery>({
      query: (query) => QueryUrl("admins/", query),
      providesTags: ["adminList"],
    }),
    addOrUpdateAdmin: builder.mutation<
      IAdmin,
      {
        slug?: string;
        user_type?: "admin" | "superadmin";
        data: IAdminFormData | FormData;
      }
    >({
      invalidatesTags: ["admin", "adminList"],
      query: ({ slug, user_type, data }) => {
        if (slug) {
          if (user_type && user_type === "admin") {
            return {
              url: `admins/${slug}/`,
              method: "PUT",
              body: data,
            };
          } else {
            return {
              url: `users/${slug}/`,
              method: "PUT",
              body: data,
            };
          }
        }
        return {
          url: `admins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    editAdmin: builder.mutation<
      IAdmin,
      {
        slug?: string;
        user_type?: "admin" | "superadmin";
        data: IAdminFormData | FormData | any;
      }
    >({
      invalidatesTags: ["admin", "adminList"],
      query: ({ slug, data }) => {
        return {
          url: `admins/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteAdmin: builder.mutation<IAdmin, string>({
      query: (slug) => ({
        url: `admins/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["admin", "adminList"],
    }),
    getUserByAdmin: builder.query<IUser[], TypeQuery>({
      query: (query) => QueryUrl("admin/users/", query),
      providesTags: ["userList"],
    }),
    getAdminData: builder.query<IAdminData, TypeQuery>({
      query: (query) => QueryUrl("admin/dashboard/", query),
      providesTags: ["admin_dashboard"],
    }),
    getAdminRecette: builder.query<IAdminRecette, TypeQuery>({
      query: (query) => QueryUrl("admin/recette/", query),
      providesTags: ["admin_recette"],
    }),
    getAdminProprietaireData: builder.query<IAdminProprietaireData, TypeQuery>({
      query: (query) => QueryUrl("admin/proprietaires/", query),
      providesTags: ["admin_proprietaire_data"],
    }),
    getAdminAgenceData: builder.query<IAdminProprietaireData, TypeQuery>({
      query: (query) => QueryUrl("admin/agence/", query),
      providesTags: ["admin_agence_data"],
    }),

    getAdminLocataires: builder.query<PaginationResults<IAdmin>, TypeQuery>({
      query: (query) => QueryUrl("locataires/", query),
      providesTags: ["admin_locataires_data"],
    }),


    // Définir la mutation avec builder.mutation
    exportUsers: builder.mutation<Blob, string>({
      query: (modelType) => ({
        url: `exporter_utilisateur/`,
        method: 'GET',
        params: {
          model_type: modelType, // Utilisez params pour ajouter le modelType comme paramètre de requête
        },
        responseType: 'blob',
      }),
    }),
    

  }),
});

export const {
	useAddOrUpdateAdminMutation,
	useAdminsListQuery,
	useEditAdminMutation,
	useLazyAdminsListQuery,
	useLazyMeAdminQuery,
	useMeAdminQuery,
	useDeleteAdminMutation,
	useGetUserByAdminQuery,
	useGetAdminDataQuery,
	useGetAdminRecetteQuery,
	useGetAdminProprietaireDataQuery,
	useGetAdminAgenceDataQuery,
  useGetAdminLocatairesQuery,
  useExportUsersMutation
} = AdminApi;
