import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ITypeProbleme, TypeProblemeFormData } from "./typeProbleme.type";

export const TypeProblemeApi = createApi({
	reducerPath: "typeProbleme",
	tagTypes: ["typeProbleme"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getTypeProbleme: build.query<PaginationResults<ITypeProbleme>, TypeQuery>({
			query: (query) => QueryUrl(`typeproblemes/`, query),
			providesTags: ["typeProbleme"],
		}),
		addOrUpdateTypeProbleme: build.mutation<ITypeProbleme, { slug?: string; data: TypeProblemeFormData }>({
			invalidatesTags: ["typeProbleme"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `typeproblemes/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `typeproblemes/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteTypeProbleme: build.mutation<ITypeProbleme, string>({
			query: (slug) => ({
				url: `typeproblemes/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["typeProbleme"],
		}),
	}),
});

export const {
    useGetTypeProblemeQuery,
    useLazyGetTypeProblemeQuery,
	useDeleteTypeProblemeMutation,
    useAddOrUpdateTypeProblemeMutation
} = TypeProblemeApi;