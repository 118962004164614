import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { rappelApi } from "../rappel/rappel.api";
import { prepareHeaders } from "../user/user.api";
import { IRappelPaiement, RappelPaiementFormData } from "./rappelPaiement.type";

export const rappelPaiementApi = createApi({
  reducerPath: "rappelPaiementApi",
  tagTypes: ["rappel_paiement", "auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addRappelPaiement: build.mutation<
      IRappelPaiement,
      { type: string; data: RappelPaiementFormData | FormData }
    >({
      query: ({ type, data }) => {
        if (type === "agence") {
          return {
            url: `agence/rappel/`,
            method: "POST",
            body: data,
          };
        }
        return {
          url: `proprietaire/rappel/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["rappel_paiement"],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(rappelApi.util.invalidateTags(["rappelsLocataire"]));
      },
    }),
    addRappelPaiementProprietaire: build.mutation<
      IRappelPaiement,
      RappelPaiementFormData | FormData
    >({
      query: (data) => ({
        url: `proprietaire/rappel/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rappel_paiement"],
    }),
  }),
});

export const {
  useAddRappelPaiementMutation,
  useAddRappelPaiementProprietaireMutation,
} = rappelPaiementApi;
