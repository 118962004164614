import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState } from "../user/user.type";
import {
	ForgetPasswordFormData,
	LoginFormData,
	LoginResult,
	RegisterFormData,
	ResetPasswordFormData,
	SocialRegisterFormData,
} from "./auth.type";
import { prepareHeaders } from "../user/user.api";



export const AuthApi = createApi({
  reducerPath: "auth",
  tagTypes: ["auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    // register user mutation
    registerUser: build.mutation<AuthState["user"], RegisterFormData>({
      query: (data) => ({
        url: "auth/register",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      // transformResponse: ({ data }) => data,
    }),
    // register agence mutation
    registerAgence: build.mutation<
      AuthState["user"],
      RegisterFormData | FormData
    >({
      query: (data) => ({
        url: "agence/inscriptions/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      // transformResponse: ({ data }) => data,
    }),
    // login user mutation
    loginUser: build.mutation<LoginResult, LoginFormData>({
      query: (data) => ({
        url: "users/connexion/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // request to reset user password mutation
    forgetPassword: build.mutation<any, ForgetPasswordFormData>({
      query: (data) => ({
        url: `users/request-password-reset/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    //reset user password mutation
    resetUserPassword: build.mutation<any, ResetPasswordFormData>({
      query: (data) => ({
        url: `users/reset-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // Register Social
    registerSocial: build.mutation<LoginResult, SocialRegisterFormData>({
      query: (data) => ({
        url: "auth/socialregister/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // Refresh token
    refreshToken: build.mutation<LoginResult, string>({
      query: (token) => ({
        url: `/users/refresh-token/`,
        method: "POST",
        body: { token },
      }),
      invalidatesTags: [],
    }),
// Marquer l'utilisaaateur offlined  lor de la deconnexion
    markUserOfline: build.mutation<any, { slug: string }>({
      query: ({ slug }) => ({
        url: `/mark_user_oline/${slug}/`,
        method: "POST",
      }),
      invalidatesTags: [],
    }),  
    
    loginwithisOnlineView: build.mutation<any, { slug: string }>({
      query: ({ slug }) => ({
        url: `/mark_user_ofoline/${slug}/`,
        method: "POST",
      }),
      invalidatesTags: [],
    }), 
    

  }),
});

export const {
	useRegisterUserMutation,
	useLoginUserMutation,
	useForgetPasswordMutation,
	useResetUserPasswordMutation,
	useRegisterSocialMutation,
	useRegisterAgenceMutation,
	useRefreshTokenMutation,
  useMarkUserOflineMutation,
  useLoginwithisOnlineViewMutation,
} = AuthApi;