import { createSlice, configureStore } from "@reduxjs/toolkit";
import { AgenceApi } from "../utils/api/agence/agence.api";
import { AdminApi } from "../utils/api/admin/admin.api";
import { AuthApi } from "../utils/api/auth/auth.api";
import { LocataireApi } from "../utils/api/locataire/locataire.api";
import { ProprieteApi } from "../utils/api/propriete/propriete.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import { ProprietaireApi } from "../utils/api/proprietaire/proprietaire.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { AnnonceApi } from "../utils/api/annonces/annonce.api";
import { rappelApi } from "../utils/api/rappel/rappel.api";
import { FactureApi } from "../utils/api/facture/facture.api";
import { EtatLieuApi } from "../utils/api/etatlieu/etatlieu.api";
import { RepresentationJusticeApi } from "../utils/api/representationJustice/representationJustice.api";
import { requeteApi } from "../utils/api/requete/requete.api";
import { rappelPaiementApi } from "../utils/api/rappelPaiement/rappelPaiement.api";
import { ChatApi } from "../utils/api/chat/chat.api";
import { ContratApi } from "../utils/api/contrat/contrat.api";
import { PaiementApi } from "../utils/api/paiement/paiement.api";
import { LitigesApi } from "../utils/api/litiges/litiges.api";
import { TagApi } from "../utils/api/Tag/tag.api";
import { ComptableApi } from "../utils/api/comptable/comptable.api";
import { EntreeApi } from "../utils/api/entree/entree.api";
import { NewsletterApi } from "../utils/api/newsletter/newsletter.api";
import { NewApi } from "../utils/api/new/news.api";
import { CategoryApi } from "../utils/api/category/category.api";
import { DepenseApi } from "../utils/api/depense/depense.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import ConditionApi from "../utils/api/condition/condition.api";
import SliderApi from "../utils/api/slider/slider.api";
import { ReseauSociauxApi } from "../utils/api/reseauSociaux/reseauSociaux.api";
import { TypeProblemeApi } from "../utils/api/typeProbleme/typeProbleme.api";
import { ProblemeApi } from "../utils/api/probleme/probleme.api";
import { CommoditesApi } from "../utils/api/commodites/commodites.api";
import { ImmeublesApi } from "../utils/api/immeubles/immeubles.api";
import { TypeRequeteApi } from "../utils/api/typeRequete/typerequete.api";
import { AccountInfoApi } from "../utils/api/accountInfo/accountInfo.api";
import { OfferApi } from "../utils/api/offer/offer.api";
import { expireataabonnementApi } from "../utils/api/offer/expireataabonnement.api";
import { FaqApi } from "../utils/api/faq/faq.api";
import { ValidationApi } from "../utils/api/validationGerant/validation.api";
import { allSubscrberApi } from "../utils/api/subscriber/subscriber.api";
import { signatureApi } from "../utils/api/signature/signature.api";
import { CompteRenduAPi } from "../utils/api/comteRendu/conterendu.api";

// Créer un slice Redux pour gérer l'état du modal
const modalSlice = createSlice({
  name: "modal",
  initialState: {
    show: false,
  },
  reducers: {
    // Reducer pour afficher le modal
    showModal: (state) => {
      state.show = true;
    },
    // Reducer pour masquer le modal
    hideModal: (state) => {
      state.show = false;
    },
  },
});

// Extraire les actions et le reducer du slice
export const { showModal, hideModal } = modalSlice.actions;

// Reducer du slice pour être utilisé dans le store
const modalReducer = modalSlice.reducer;

const store = configureStore({
  reducer: {
    modal: modalReducer, // Utilise le nom du slice comme clé
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [LocataireApi.reducerPath]: LocataireApi.reducer,
    [ProprieteApi.reducerPath]: ProprieteApi.reducer,
    [AdminApi.reducerPath]: AdminApi.reducer,
    [LocataireApi.reducerPath]: LocataireApi.reducer,
    [AgenceApi.reducerPath]: AgenceApi.reducer,
    [ProprietaireApi.reducerPath]: ProprietaireApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
    [AnnonceApi.reducerPath]: AnnonceApi.reducer,
    [rappelApi.reducerPath]: rappelApi.reducer,
    [FactureApi.reducerPath]: FactureApi.reducer,
    [EtatLieuApi.reducerPath]: EtatLieuApi.reducer,
    [RepresentationJusticeApi.reducerPath]: RepresentationJusticeApi.reducer,
    [requeteApi.reducerPath]: requeteApi.reducer,
    [rappelPaiementApi.reducerPath]: rappelPaiementApi.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
    [ContratApi.reducerPath]: ContratApi.reducer,
    [PaiementApi.reducerPath]: PaiementApi.reducer,
    [LitigesApi.reducerPath]: LitigesApi.reducer,
    [TagApi.reducerPath]: TagApi.reducer,
    [NewApi.reducerPath]: NewApi.reducer,
    [ComptableApi.reducerPath]: ComptableApi.reducer,
    [EntreeApi.reducerPath]: EntreeApi.reducer,
    [NewsletterApi.reducerPath]: NewsletterApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [DepenseApi.reducerPath]: DepenseApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [SliderApi.reducerPath]: SliderApi.reducer,
    [ReseauSociauxApi.reducerPath]: ReseauSociauxApi.reducer,
    [TypeProblemeApi.reducerPath]: TypeProblemeApi.reducer,
    [ProblemeApi.reducerPath]: ProblemeApi.reducer,
    [CommoditesApi.reducerPath]: CommoditesApi.reducer,
    [ImmeublesApi.reducerPath]: ImmeublesApi.reducer,
    [TypeRequeteApi.reducerPath]: TypeRequeteApi.reducer,
    [AccountInfoApi.reducerPath]: AccountInfoApi.reducer,
    [OfferApi.reducerPath]: OfferApi.reducer,
    [expireataabonnementApi.reducerPath]: expireataabonnementApi.reducer,
    [FaqApi.reducerPath]: FaqApi.reducer,
    [ValidationApi.reducerPath]: ValidationApi.reducer,
    [allSubscrberApi.reducerPath]: allSubscrberApi.reducer,
    [signatureApi.reducerPath]: signatureApi.reducer,
    [CompteRenduAPi.reducerPath]: CompteRenduAPi.reducer,
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    ProprieteApi.middleware,
    AdminApi.middleware,
    LocataireApi.middleware,
    AgenceApi.middleware,
    ProprietaireApi.middleware,
    NotificationApi.middleware,
    AnnonceApi.middleware,
    rappelApi.middleware,
    FactureApi.middleware,
    EtatLieuApi.middleware,
    RepresentationJusticeApi.middleware,
    requeteApi.middleware,
    rappelPaiementApi.middleware,
    ChatApi.middleware,
    ContratApi.middleware,
    PaiementApi.middleware,
    LitigesApi.middleware,
    TagApi.middleware,
    NewApi.middleware,
    ComptableApi.middleware,
    EntreeApi.middleware,
    CategoryApi.middleware,
    DepenseApi.middleware,
    ContactApi.middleware,
    ConditionApi.middleware,
    SliderApi.middleware,
    ReseauSociauxApi.middleware,
    TypeProblemeApi.middleware,
    ProblemeApi.middleware,
    CommoditesApi.middleware,
    ImmeublesApi.middleware,
    TypeRequeteApi.middleware,
    AccountInfoApi.middleware,
    OfferApi.middleware,
    expireataabonnementApi.middleware,
    FaqApi.middleware,
    ValidationApi.middleware,
    allSubscrberApi.middleware,
    signatureApi.middleware,
    CompteRenduAPi.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
