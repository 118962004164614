import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { CommentFormData, INews, NewsFormData, ResponseCommentFormData } from "./news.type";

export const NewApi = createApi({
	reducerPath: "newsApi",
	tagTypes: ["news", "newsItem", "similarNews"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getNews: build.query<PaginationResults<INews>, TypeQuery>({
			query: (query) => QueryUrl(`news/`, query),
			providesTags: ["news"],
		}),
		getSimilarNews: build.query<INews[], void>({
			query: () => `similar_news/`,
			providesTags: ["news", "similarNews"],
			transformResponse: ({ results }) => results,
		}),
		getNewsForVisitors: build.query<PaginationResults<INews>, TypeQuery>({
			query: (query) => QueryUrl(`news_for_visitors/`, query),
			providesTags: ["news"],
		}),
		addOrUpdateNew: build.mutation<INews, { slug?: string; data: NewsFormData | FormData }>(
			{
				invalidatesTags: ["news"],
				query: ({ slug, data }) => {
					if (slug) {
						return {
							url: `news/${slug}/`,
							method: "PUT",
							body: data,
						};
					}
					return {
						url: `news/`,
						method: "POST",
						body: data,
					};
				},
				transformResponse: ({ data }) => data,
			}
		),
		findNewsBySlug: build.query<INews, string>({
			query: (slug) => `news/${slug}/`,
			providesTags: ["newsItem"],
		}),
		deleteNew: build.mutation<INews, string>({
			query: (slug) => ({
				url: `news/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["news", "newsItem"],
		}),
		deleteCommentNew: build.mutation<INews, string>({
			query: (slug) => ({
				url: `commentnews/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["news", "newsItem"],
		}),
		postComment: build.mutation<INews, CommentFormData>({
			invalidatesTags: ["news"],
			query: (data) => {
				return {
					url: `commentnews/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		postResponseComment: build.mutation<INews, ResponseCommentFormData>({
			invalidatesTags: ["news"],
			query: (data) => {
				return {
					url: `reponsecommentnews/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteResponseCommentNew: build.mutation<INews, string>({
			query: (slug) => ({
				url: `reponsecommentnews/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["news", "newsItem"],
		}),
	}),
});

export const {
	useGetNewsQuery,
	useLazyGetNewsQuery,
	useDeleteNewMutation,
	useAddOrUpdateNewMutation,
	useGetNewsForVisitorsQuery,
	useLazyFindNewsBySlugQuery,
	usePostCommentMutation,
	useGetSimilarNewsQuery,
	usePostResponseCommentMutation,
	useDeleteCommentNewMutation,
	useDeleteResponseCommentNewMutation
} = NewApi;

export function useNewsFromLocation(): [INews, boolean, string, (slug: string) => any] {
	const itemState = useLocationState<INews | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyFindNewsBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/news", { replace: false });
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			setItem(data);
		}
	}, [data]);

	return [item as INews, isLoading, slug as string, findBySlug];
}
