import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { DetailTypeRequeteFormValues, DetailTypeRequeteItemData, IDetailTypeRequete, ITypeRequete, TypeRequeteFormData } from "./typeRequete.type";

export const TypeRequeteApi = createApi({
	reducerPath: "typeRequete",
	tagTypes: ["typeRequete"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getTypeRequete: build.query<PaginationResults<ITypeRequete>, TypeQuery>({
			query: (query) => QueryUrl(`typerequetes/`, query),
			providesTags: ["typeRequete"],
		}),
		addOrUpdateTypeRequete: build.mutation<ITypeRequete, { slug?: string; data: TypeRequeteFormData }>({
			invalidatesTags: ["typeRequete"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `typerequetes/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `typerequetes/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteTypeRequete: build.mutation<ITypeRequete, string>({
			query: (slug) => ({
				url: `typerequetes/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["typeRequete"],
		}),

        addDetailTypeRequete: build.mutation<
			IDetailTypeRequete,
			DetailTypeRequeteFormValues | { detail_type_requetes: DetailTypeRequeteItemData[] }
		>({
			query: (data) => ({
				url: `detail_type_requetes_multiple/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["typeRequete"],
		}),
		editDetailTypeRequete: build.mutation<
            IDetailTypeRequete,
			{ slug?: IDetailTypeRequete["slug"]; data: DetailTypeRequeteItemData }
		>({
			invalidatesTags: ["typeRequete"],
			query: ({ slug, data }) => ({
				url: `detail_type_requetes/${slug}/`,
				method: "PUT",
				body: data,
			}),
		}),
		deleteDetailTypeRequete: build.mutation<IDetailTypeRequete, string>({
			query: (slug) => ({
				url: `detail_type_requetes/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["typeRequete"],
		}),
		deleteAllDetailTypeRequete: build.mutation<IDetailTypeRequete, string>({
			query: (slug) => ({
				url: `detail_type_requetes/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["typeRequete"],
		}),
	}),
});

export const {
    useGetTypeRequeteQuery,
    useLazyGetTypeRequeteQuery,
	useDeleteTypeRequeteMutation,
    useAddOrUpdateTypeRequeteMutation,
    useAddDetailTypeRequeteMutation,
    useEditDetailTypeRequeteMutation,
    useDeleteDetailTypeRequeteMutation,
    useDeleteAllDetailTypeRequeteMutation,
} = TypeRequeteApi;