import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { FaqFormData, faqFormData, IAfaq } from "./faq.type";  // Assurez-vous d'importer le bon type ici.

// ... (autres imports)

export const FaqApi = createApi({
  reducerPath: "faq",  
  tagTypes: ["faqs", "faq_slug"],   
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    faqs: build.query<PaginationResults<IAfaq>, TypeQuery>({
      query: (query) => QueryUrl("faqs/", query),
      providesTags: ["faqs"],
    }),

    createFaq: build.mutation<FaqFormData, {slug?: string; data: faqFormData }>({
      invalidatesTags: ["faqs"],
      query: ({slug,data}) => {
				if (slug) {
					return {
						url: `faqs/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `faq/create/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
    }),
    deleteFaq: build.mutation<FaqFormData, string>({
			query: (slug) => ({
				url: `faqs/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["faqs"],
		}),
  }),
});

export const { useCreateFaqMutation, useFaqsQuery, useDeleteFaqMutation } = FaqApi;