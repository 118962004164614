import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ITag, TagFormData } from "./tag.type";

export const TagApi = createApi({
	reducerPath: "tags",
	tagTypes: ["tags"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getTags: build.query<PaginationResults<ITag>, TypeQuery>({
			query: (query) => QueryUrl(`tags/`, query),
			providesTags: ["tags"],
		}),
		addOrUpdateTag: build.mutation<ITag, { slug?: string; data: TagFormData }>({
			invalidatesTags: ["tags"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `tags/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `tags/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteTag: build.mutation<ITag, string>({
			query: (slug) => ({
				url: `tags/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["tags"],
		}),
	}),
});

export const {
	useGetTagsQuery,
	useLazyGetTagsQuery,
	useDeleteTagMutation,
	useAddOrUpdateTagMutation,
} = TagApi;
