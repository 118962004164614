import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { NewsletterFormData, INewsletter } from "./newsletter.type";

export const NewsletterApi = createApi({
	tagTypes: ["newsletter", "allNewsletter", "desabonner_user"],
	reducerPath: "newsletterApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getNewsletter: builder.query<PaginationResults<INewsletter>, TypeQuery>({
			query: (query) => QueryUrl(`newletters/`, query),
			providesTags: ["allNewsletter"],
		}),
		subscribeNewsletter: builder.mutation<NewsletterFormData, any>({
			query: (data) => ({
				url: "newletters/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["newsletter"],
		}),
		desabonnerUserNewsletter: builder.mutation< NewsletterFormData, string>({
			query: (email) => `desabonnement/?email=${email}`,
		}),
	}),
});

export const { 
	useSubscribeNewsletterMutation, 
	useGetNewsletterQuery,  
	useDesabonnerUserNewsletterMutation,
} = NewsletterApi;
