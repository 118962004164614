import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { IContrat, ContratFormData } from "./contrat.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ContratApi = createApi({
  reducerPath: "ContratApi",
  tagTypes: ["contrats", "contrats_slug", "contratsByLocataire", "auth","contratByProprietaire"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getContrat: builder.query<PaginationResults<IContrat>, TypeQuery>({
      query: (query) => QueryUrl("contrats/", query),
      providesTags: ["contrats"],
    }),
    addOrUpdateContrat: builder.mutation<IContrat,{ slug?: string; data: ContratFormData | FormData }>({
      invalidatesTags: ["contrats", "contrats_slug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `contrats/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `contrats/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrUpdateContratAdminAgence: builder.mutation<IContrat,{ slug?: string; data: ContratFormData | FormData }>({
      invalidatesTags: ["contrats", "contrats_slug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `contrats/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `admin/contrat/agence/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrUpdateContratAdminProprietaire: builder.mutation<IContrat,{ slug?: string; data: ContratFormData | FormData }>({
      invalidatesTags: ["contrats", "contrats_slug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `contrats/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `admin/contrat/proprietaire/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteContrat: builder.mutation<ContratFormData, string>({
      query: (slug) => ({
        url: `contrats/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contrats", "contrats_slug"],
    }),
    getTypeContrat: builder.query<PaginationResults<IContrat>, TypeQuery>({
      query: (query) => QueryUrl("typerequetes/", query),
      providesTags: ["contrats"],
    }),
    getContratAdminAgence: builder.query<PaginationResults<IContrat>, TypeQuery>({
      query: (query) => QueryUrl("admin_agence/contrats/", query),
      providesTags: ["contrats"],
    }),
    getContratByLocataireBySlug: builder.query<PaginationResults<IContrat>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataires/${slug}/contrats/`, query),
      providesTags: ["contratsByLocataire"],
    }),
    getContratByAgenceBySlug: builder.query<PaginationResults<IContrat>,TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/contrats/`, query),
      providesTags: ["contrats"],
    }),
    getContratBySlug: builder.query<IContrat | any, TypeQuery>({
      query: (slug) => `contrats/${slug}`,
      providesTags: ["contrats_slug"],
    }),
		getContratByProprietaire:  builder.query<PaginationResults<IContrat>,TypeQuery>({
			query: ({ slug, ...query }) => {
				return QueryUrl(`proprietaireqngb/${slug}/contrats/`, query);
			},
			providesTags: ["contratByProprietaire"],
		}),
		getContratByProprietaireQGSB:  builder.query<PaginationResults<IContrat>,TypeQuery>({
			query: ({ slug, ...query }) => {
				return QueryUrl(`proprietaires/${slug}/contrats/`, query);
			},
			providesTags: ["contratByProprietaire"],
		}),
    getContratAdmin: builder.query<PaginationResults<IContrat>, TypeQuery>({
      query: (query) => QueryUrl("admin/contrats/", query),
      providesTags: ["contrats"],
    }),
  }),
});

export const {
  useAddOrUpdateContratMutation,
  useGetContratQuery,
  useDeleteContratMutation,
  useGetContratByLocataireBySlugQuery,
  useLazyGetContratByLocataireBySlugQuery,
  useGetContratByAgenceBySlugQuery,
  useLazyGetContratByAgenceBySlugQuery,
  useLazyGetContratBySlugQuery,
  useGetContratByProprietaireQuery,
  useAddOrUpdateContratAdminAgenceMutation,
  useAddOrUpdateContratAdminProprietaireMutation,
  useGetContratAdminAgenceQuery,
  useGetContratByProprietaireQGSBQuery,
  useGetContratAdminQuery
} = ContratApi;
