import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IFacture, FactureFormData,Gquittence } from "./facture.type";

export const FactureApi = createApi({
  reducerPath: "FactureAPi",
  tagTypes: ["facture", "factureLocataire", "factureProprio"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFacture: builder.query<PaginationResults<IFacture>, TypeQuery>({
      query: (query) => QueryUrl(`factures/`, query),
      providesTags: ["facture"],
    }),
    getFactureByAgence: builder.query<PaginationResults<IFacture>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/factures/`, query),
      providesTags: ["facture"],
    }),
    getFactureByLocataire: builder.query<
      PaginationResults<IFacture>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataires/${slug}/factures/`, query),
      providesTags: ["factureLocataire"],
    }),
    getFactureByProprietaire: builder.query<
      PaginationResults<IFacture>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`proprietaireqgb/${slug}/factures/`, query),
      providesTags: ["factureProprio"],
    }),
    addOrUpdateFacture: builder.mutation<
      IFacture,
      { slug?: string; data: FactureFormData | FormData }
    >({
      invalidatesTags: ["facture", "factureLocataire", "factureProprio"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `factures/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `factures/`,
          method: "POST",
          body: data,
        };
      },
    }),
    findFactureBySlug: builder.query<IFacture, string>({
      query: (slug) => `factures/${slug}/`,
    }),
    deleteFacture: builder.mutation<IFacture, string>({
      query: (slug) => ({
        url: `factures/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["facture", "factureProprio"],
    }),
    generateQuittance: builder.mutation<void, { email: string }>({
      query: (data) => ({
        url: `gerantequittancebyagence/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["facture", "factureProprio"],
    }),



  }),
});

export const {
	useGetFactureQuery,
	useAddOrUpdateFactureMutation,
	useDeleteFactureMutation,
	useLazyFindFactureBySlugQuery,
	useGetFactureByAgenceQuery,
	useGetFactureByLocataireQuery,
  useGetFactureByProprietaireQuery,
  useGenerateQuittanceMutation,
} = FactureApi;
