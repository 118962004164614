import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { AgenceApi } from "../agence/agence.api";
import { prepareHeaders } from "../user/user.api";
import { AuthState, IComptableData, IUser, PaginationResults, TypeQuery } from "../user/user.type";
import { IComptable, ComptableFormData } from "./comptable.type";

export const ComptableApi = createApi({
  reducerPath: "comptableApi",
  tagTypes: [
    "comptables",
    "comptable_slug",
    "comptables_entrees",
    "comptables_depenses",
    "comptable_user",
    "auth",
    "comptable_dashboard",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrUpdateComptable: build.mutation<
      IComptable,
      { slug?: string; data: ComptableFormData | FormData }
    >({
      invalidatesTags: [
        "comptables",
        "comptable_slug",
        "comptables_entrees",
        "comptables_depenses",
        "comptable_user",
        "comptable_dashboard",
      ],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `comptables/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `comptables/`,
          method: "POST",
          body: data,
        };
      },
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(AgenceApi.util.invalidateTags(["comptabilite_comptable"]));
      },
    }),
    deleteComptable: build.mutation<ComptableFormData, string>({
      query: (slug) => ({
        url: `comptables/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "comptables",
        "comptable_slug",
        "comptables_entrees",
        "comptables_depenses",
        "comptable_user",
        "comptable_dashboard",
      ],
    }),
    getComptableInfoBySlug: build.query<IComptable | any, TypeQuery>({
      query: (slug) => `comptables/${slug}`,
      providesTags: ["comptable_slug"],
    }),
    getDepensesByComptableBySlug: build.query<
      PaginationResults<IComptable>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`comptables/${slug}/depenses/`, query),
      providesTags: ["comptables_depenses"],
    }),
    getEntreesByComptableBySlug: build.query<
      PaginationResults<IComptable>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`comptables/${slug}/entrees/`, query),
      providesTags: ["comptables_entrees"],
    }),
    getUserComptableBySlug: build.query<IUser[], TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`comptable/${slug}/users/`, query),
      providesTags: ["comptable_user"],
    }),
    getComptableData: build.query<IComptableData, TypeQuery>({
      query: ({ slug, type, ...query }) =>
        QueryUrl(`comptable/${slug}/dashboard/`, { type, ...query }),
      providesTags: ["comptable_dashboard"],
    }),
  }),
});

export const {
  useAddOrUpdateComptableMutation,
  useDeleteComptableMutation,
  useGetDepensesByComptableBySlugQuery,
  useLazyGetDepensesByComptableBySlugQuery,
  useGetEntreesByComptableBySlugQuery,
  useLazyGetEntreesByComptableBySlugQuery,
  useGetComptableInfoBySlugQuery,
  useLazyGetComptableInfoBySlugQuery,
  useGetUserComptableBySlugQuery,
  useGetComptableDataQuery
} = ComptableApi;

export function useComptableFromLocation(): [
  IComptable,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IComptable | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  // const [findBySlug, { data, isError, isLoading }] = useLazyGetComptableByAgenceBySlugQuery();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetComptableInfoBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/comptable/dashboard", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    }
  }, [data]);

  return [item as IComptable, isLoading, slug as string, findBySlug as any];
}

export function useComptableInfoFromLocation(): [
  IComptable,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IComptable | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  // const [findBySlug, { data, isError, isLoading }] = useLazyGetComptableByAgenceBySlugQuery();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetComptableInfoBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/comptable/dashboard", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    }
  }, [data]);

  return [item as IComptable, isLoading, slug as string, findBySlug as any];
}

export function useComptableInfoDetailsFromLocation(): [
  IComptable,
  (slug: string) => any
] {
  const itemState = useLocationState<IComptable | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
    useLazyGetComptableInfoBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
    // console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    // console.log("slug", data);
      setItem(data);
    }
  }, [data]);

  return [item as IComptable, findBySlug as any];
}
