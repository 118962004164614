/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Provider } from "react-redux";
import { fr } from "yup-locales";
import { setLocale } from "yup";
setLocale(fr);
import "./index.css";
import App from "./App";
import store from "./redux/store";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");
import "react-multi-carousel/lib/styles.css";
import "volkeno-react-country-state-city/dist/index.css";
import "animate.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-dates/lib/css/_datepicker.css";
// {A Decommenter Apres maintenance}
// import "page-http-code/dist/index.css";
import EnMaintenance from "./components/landingPage/EnMaintenance";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
    <EnMaintenance />
      {/* <App /> */}
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
