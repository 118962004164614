import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ITypeCommodites, TypeCommoditesFormData } from "./commodites.type";

export const CommoditesApi = createApi({
	reducerPath: "commodites",
	tagTypes: ["commodites"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getCommodites: build.query<PaginationResults<ITypeCommodites>, TypeQuery>({
			query: (query) => QueryUrl(`commodites/`, query),
			providesTags: ["commodites"],
		}),
		addOrUpdateCommodites: build.mutation<ITypeCommodites, { slug?: string; data: TypeCommoditesFormData }>({
			invalidatesTags: ["commodites"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `commodites/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `commodites/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteCommodites: build.mutation<ITypeCommodites, string>({
			query: (slug) => ({
				url: `commodites/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["commodites"],
		}),
	}),
});

export const {
    useGetCommoditesQuery,
    useLazyGetCommoditesQuery,
	useDeleteCommoditesMutation,
    useAddOrUpdateCommoditesMutation
} = CommoditesApi;